// Actions
import {
  PICS_INVENTORY_CHECKIN_ADD,
  PICS_INVENTORY_CHECKIN_REMOVE,
  PICS_INVENTORY_CHECKIN_CLEAN,
  PICS_INVENTORY_CHECKOUT_CLEAN,
  PICS_INVENTORY_SET,
  PICS_INVENTORY_CHECKOUT_ADD,
  PICS_INVENTORY_CHECKOUT_REMOVE,
  PICS_INVENTORY_CLEAN
} from 'redux/actions/picsActions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PICS_INVENTORY_CHECKIN_ADD:
      return {
        ...state,
        incoming: { data: [...state.incoming?.data, action.payload] }
      };
    case PICS_INVENTORY_CHECKIN_REMOVE:
      return {
        ...state,

        incoming: {
          data: [...state.incoming.data.filter(pic => pic.fileId !== action.payload.fileId)]
        }
      };

    case PICS_INVENTORY_CHECKIN_CLEAN:
      return {
        ...state,

        incoming: { data: [] }
      };
    case PICS_INVENTORY_CLEAN:
      return {
        ...state,
        incoming: { data: [] },
        checkout: { data: [] }
      };

    case PICS_INVENTORY_SET:
      return {
        ...state,
        [action.payload?.key]: {
          data: action.payload.data
        }
      };
    case PICS_INVENTORY_CHECKOUT_ADD:
      return {
        ...state,
        checkout: { data: [...state.checkout?.data, action.payload] }
      };
    case PICS_INVENTORY_CHECKOUT_REMOVE:
      return {
        ...state,
        checkout: {
          data: [...state.checkout.data.filter(pic => pic.fileId !== action.payload.fileId)]
        }
      };
    case PICS_INVENTORY_CHECKOUT_CLEAN:
      return {
        ...state,

        checkout: { data: [] }
      };
    default:
      return state;
  }
};
