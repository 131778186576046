import {
  PAYMENTS_SET_RESULTS,
  PAYMENTS_CLEAN_RESULTS,
  PAYMENT_DETAIL_REQUEST,
  PAYMENT_DETAIL_SUCCESS,
  PAYMENT_DETAIL_FAIL,
  PAYMENT_DETAIL_CLEAN,
  VALIDATE_PAYMENT_FILE_REQUEST,
  VALIDATE_PAYMENT_FILE_SUCCESS,
  VALIDATE_PAYMENT_FILE_FAIL,
  CANCEL_PAYMENT_REQUEST,
  CANCEL_PAYMENT_SUCCESS,
  CANCEL_PAYMENT_FAIL
} from '../actions/paymentsActions';

let initial = {};
function paymentsReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  let loadingDefault = {
    isLoading: false
  };

  let paymentDetail = {
    ...loadingDefault,
    data: false,
    error: false
  };

  switch (action.type) {
    case PAYMENTS_SET_RESULTS:
      return {
        ...state,
        searchPaymentsResults: action.payload
      };
    case PAYMENTS_CLEAN_RESULTS:
      return { ...state, searchPaymentsResults: [] };
    case PAYMENT_DETAIL_REQUEST:
      return { ...state, paymentDetail: { ...paymentDetail, isLoading: true } };
    case PAYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        paymentDetail: {
          ...paymentDetail,
          data: action.payload,
          references: action.payload.references
        }
      };
    case PAYMENT_DETAIL_FAIL:
      return { ...state, paymentDetail: { ...paymentDetail, error: true } };
    case PAYMENT_DETAIL_CLEAN:
      return { ...state, paymentDetail: paymentDetail };
    case VALIDATE_PAYMENT_FILE_REQUEST:
      return {
        ...state,
        validateFiles: {
          ...action.payload.validateRequest
        }
      };
    case VALIDATE_PAYMENT_FILE_SUCCESS:
      return {
        ...state,
        paymentDetail: {
          ...state.paymentDetail,
          references: action.payload.data
        },
        validateFiles: {
          approvedRequest: false,
          rejectedRequest: false
        }
      };
    case VALIDATE_PAYMENT_FILE_FAIL:
      return {
        ...state,
        validateFiles: {
          approvedRequest: false,
          rejectedRequest: false
        }
      };
    case CANCEL_PAYMENT_REQUEST:
      return {
        ...state,
        cancelPayment: {
          ...paymentDetail,
          isLoading: true
        }
      };
    case CANCEL_PAYMENT_SUCCESS:
      return {
        ...state,
        cancelPayment: {
          ...paymentDetail,
          isLoading: false
        }
      };
    case CANCEL_PAYMENT_FAIL:
      return {
        ...state,
        cancelPayment: {
          ...paymentDetail,
          error: true
        }
      };
    default:
      return state;
  }
}
export default paymentsReducer;
