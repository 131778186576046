import {
  ADD_PHOTO_CASE,
  PHOTOS_DOWNLOADED,
  DELETE_PHOTO_CASE,
  CHECK_PHOTO_CASE,
  CLEAR_INFO_PHOTO_CASE,
  CHECK_MARKET_CASE,
  MODAL_PAGE,
  CLEAR_MODAL_PAGE,
  MARK_AS_FAVORITE,
  IS_FROM,
  CASE_IMAGES_FAIL,
  CASE_IMAGES_SUCCESS,
  REQUEST_CASE_IMAGES,
  CASE_DETAIL_REQUEST,
  CASE_DETAIL_SUCCESS,
  CASE_DETAIL_FAIL,
  CLEAN_CURRENT_CASE,
  TRANSFER_STATUS_SUCCESS,
  TRANSFER_STATUS_FAIL,
  TRANSFER_STATUS_REQUEST,
  REQUEST_CLOSE_CASE,
  CASE_CLOSE_SUCCESS,
  CASE_CLOSE_FAIL,
  REQUEST_UPLOAD_CASE_PHOTO,
  UPLOAD_CASE_PHOTO_SUCCESS,
  UPLOAD_CASE_PHOTO_FAIL,
  VEHICLE_CASE_UPDATE_REQUEST,
  VEHICLE_CASE_UPDATE_SUCCESS,
  VEHICLE_CASE_UPDATE_FAIL,
  MARKETS_ALLOWED_REQUEST,
  MARKETS_ALLOWED_SUCCESS,
  MARKETS_ALLOWED_FAIL,
  REQUEST_DIVERSES_FILES,
  DIVERSES_FILES_SUCCESS,
  DIVERSES_FILES_FAIL,
  REQUEST_UPLOAD_DIVERSES_FILE,
  UPLOAD_DIVERSES_FILE_SUCCESS,
  UPLOAD_DIVERSES_FILE_FAIL,
  SET_CASE_IMAGES_LOADING,
  DELIVERY_INFO_REQUEST,
  DELIVERY_INFO_SUCCESS,
  DELIVERY_INFO_FAIL,
  REGISTER_DELIVERY_REQUEST,
  REGISTER_DELIVERY_SUCCESS,
  REGISTER_DELIVERY_FAIL,
  POST_BDELIVERY_REQUEST,
  POST_BDELIVERY_SUCCESS,
  POST_BDELIVERY_FAIL,
  GET_INVENTORY_HISTORY_FAIL,
  GET_INVENTORY_HISTORY_SUCCESS,
  GET_INVENTORY_HISTORY_REQUEST,
  CLEAN_INCOMING_DETAIL,
  saveVideoUrlRequest,
  saveVideoUrlSuccess,
  saveVideoUrlFail,
  SAVE_VIDEO_URL_REQUEST,
  SAVE_VIDEO_URL_SUCCESS,
  SAVE_VIDEO_URL_FAIL,
  GET_VIDEO_URL_REQUEST,
  GET_VIDEO_URL_SUCCESS,
  GET_VIDEO_URL_FAIL,
  DELETE_VIDEO_URL_REQUEST,
  DELETE_VIDEO_URL_SUCCESS,
  DELETE_VIDEO_URL_FAIL,
  CHANGE_CASE_PHOTOS,
  GET_CASE_LOGS_REQUEST,
  GET_CASE_LOGS_SUCCESS,
  GET_CASE_LOGS_FAIL,
} from '../actions/caseActions';
import {
  FAV_COUNT_SUCCESS,
  PICS_AUDATEX_SUCCESS,
  PICS_AUDATEX_FAIL,
  PICS_AUDATEX_REQUEST,
  PICS_AUDATEX_CLEAN,
  PICS_AUDATEX_SET,
  PICS_AUDATEX_ADD,
  PICS_AUDATEX_REMOVE,
  PICS_AUDATEX_SET_BLOCK_REQUEST,
  PICS_AUDATEX_SET_PICS_EXIST
} from '../actions/picsActions';
import {
  AWARD_BYCASE_REQUEST,
  AWARD_BYCASE_SUCCESS,
  AWARD_BYCASE_FAIL,
  AWARD_BYCASE_CLEAN
} from '../actions/awardingsActions';
let initial = {};

function caseReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }
  const defaultState = {
    isLoading: false,
    data: false
  };

  let defaultAwardState = {
    ...state,
    awardDetail: false,
    request: false
  };

  const defaultStateVehicleUpdate = {
    ...state,
    updateCase: {
      isLoading: false
    }
  };

  switch (action.type) {
    case REGISTER_DELIVERY_REQUEST:
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          isUploading: true
        }
      };

    case REGISTER_DELIVERY_SUCCESS:
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          isUploading: false
        }
      };

    case REGISTER_DELIVERY_FAIL:
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          isUploading: false
        }
      };

    case DELIVERY_INFO_REQUEST:
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          isLoading: true
        }
      };

    case DELIVERY_INFO_SUCCESS:
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          isLoading: true,
          data: action.payload
        }
      };

    case DELIVERY_INFO_FAIL:
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          isLoading: false
        }
      };

    case REQUEST_UPLOAD_DIVERSES_FILE:
      return {
        ...state,
        diverseFiles: {
          ...state.diverseFiles,
          isUploading: true
        }
      };

    case UPLOAD_DIVERSES_FILE_SUCCESS:
      return {
        ...state,
        diverseFiles: {
          ...state.diverseFiles,
          isUploading: false
        }
      };

    case UPLOAD_DIVERSES_FILE_FAIL:
      return {
        ...state,
        diverseFiles: {
          ...state.diverseFiles,
          isUploading: false
        }
      };

    case REQUEST_DIVERSES_FILES:
      return {
        ...state,
        diverseFiles: {
          ...state.diverseFiles,
          isDownloading: true
        }
      };

    case DIVERSES_FILES_SUCCESS:
      return {
        ...state,
        diverseFiles: {
          ...state.diverseFiles,
          isDownloading: false,
          data: action.payload
        }
      };

    case DIVERSES_FILES_FAIL:
      return {
        ...state,
        diverseFiles: {
          ...state.diverseFiles,
          isDownloading: false
        }
      };

    case CASE_DETAIL_REQUEST:
      return {
        ...state,
        currentCase: { data: false, isLoading: true, canBeMoved: false }
      };

    case CASE_DETAIL_SUCCESS:
      return {
        ...state,
        currentCase: {
          ...state.currentCase,
          isLoading: false,
          data: action.payload.vehicleCase
          // canBeMoved: action.payload.vehicleCase.enableLocation
        }
      };

    case CASE_DETAIL_FAIL:
      return {
        ...state,
        currentCase: defaultState
      };

    case CLEAN_CURRENT_CASE:
      // eslint-disable-next-line no-case-declarations
      const temp = { ...state };
      delete temp.currentCase;
      return {
        ...temp
      };
    case TRANSFER_STATUS_REQUEST:
      return {
        ...state,
        currentCase: { ...state.currentCase }
      };
    case TRANSFER_STATUS_SUCCESS:
      return {
        ...state,
        currentCase: { ...state.currentCase, canBeMoved: action.payload.canBeMoved }
      };
    case TRANSFER_STATUS_FAIL:
      return {
        ...state,
        currentCase: { ...state.currentCase }
      };

    case SET_CASE_IMAGES_LOADING:
      return {
        ...state,
        photos: {
          isLoading: action.payload,
          //TO DO: DELETE THIS NEXT LINE
          data: [...state.photos.data]
        }
      };

    case ADD_PHOTO_CASE:
      return {
        ...state,
        photos: {
          isLoading: state.photos.isLoading,
          data: [...state.photos.data, action.photo]
        }
      };

    case DELETE_PHOTO_CASE:
      return {
        ...state,
        photos: {
          ...state.photos,
          data: [...state.photos.data.filter(ph => ph.id !== action.id)]
        }
      };

    case CHECK_PHOTO_CASE: {
      return {
        ...state,
        checkedPhotos: action.photoInfo
      };
    }

    case CLEAR_INFO_PHOTO_CASE: {
      return {
        ...state,
        photosInfo: [],
        photos: {
          isLoading: false,
          data: []
        },
        checkedPhotos: [],
        wasDownloaded: false,
        isFromAudatex: false
      };
    }

    case IS_FROM: {
      return {
        ...state,
        isFromAudatex: action.isFromAudatex
      };
    }

    case PHOTOS_DOWNLOADED: {
      return {
        ...state,
        wasDownloaded: action.wasDownloaded
      };
    }

    case CHECK_MARKET_CASE:
      return {
        ...state,
        chkMarkets: action.marketsInfo
      };

    case MODAL_PAGE:
      return {
        ...state,
        modalPage: action.page
      };

    case CLEAR_MODAL_PAGE:
      return {
        ...state,
        modalPage: 1
      };

    case MARK_AS_FAVORITE:
      return {
        ...state,
        photos: {
          ...state.photos,
          data: action.payload
        }
      };

    case CHANGE_CASE_PHOTOS:
      return{
        ...state,
        photos:{
          ...state.photos,
          data: action.photos
        }
      };

    case REQUEST_CASE_IMAGES:
      return {
        ...state,
        photos: {
          isLoading: true,
          data: []
        }
      };

    case CASE_IMAGES_SUCCESS:
      return {
        ...state,
        photos: {
          isLoading: false,
          data: action.photos
        }
      };

    case CASE_IMAGES_FAIL:
      return {
        ...state,
        photos: {
          isLoading: false,
          data: []
        }
      };

    case PICS_AUDATEX_REQUEST:
      return {
        ...state,
        audatexPics: {
          ...state.audatexPics,
          isLoading: true,
          data: []
        }
      };
    case PICS_AUDATEX_CLEAN:
      return {
        ...state,
        audatexPics: {
          isLoading: false,
          data: []
        }
      };

    case PICS_AUDATEX_SUCCESS:
      return {
        ...state,
        audatexPics: {
          ...state.audatexPics,
          isLoading: false,
          data: action.payload
        }
      };

    case PICS_AUDATEX_FAIL:
      return {
        ...state,
        audatexPics: {
          ...state.audatexPics,
          isLoading: false,
          data: []
        }
      };

    case PICS_AUDATEX_ADD:
      return {
        ...state,
        audatexPics: {
          ...state.audatexPics,
          data: [...state.audatexPics.data, action.payload]
        }
      };

    case PICS_AUDATEX_REMOVE:
      return {
        ...state,
        audatexPics: {
          ...state.audatexPics,
          data: [...state.audatexPics.data.filter(pic => pic.fileId !== action.payload.fileId)]
        }
      };

    case PICS_AUDATEX_SET:
      return {
        ...state,
        audatexPics: {
          ...state.audatexPics,
          data: action.payload
        }
      };

    case PICS_AUDATEX_SET_BLOCK_REQUEST:
      return {
        ...state,
        audatexPics: {
          ...state.audatexPics,
          blockRequest: action.payload
        }
      };

    case PICS_AUDATEX_SET_PICS_EXIST:
      return {
        ...state,
        audatexPics: {
          ...state.audatexPics,
          audatexPicsExist: action.payload
        }
      };

    case REQUEST_CLOSE_CASE:
      return {
        ...state,
        deletingCase: {
          isLoading: true
        }
      };

    case CASE_CLOSE_SUCCESS:
      return {
        ...state,
        deletingCase: {
          isLoading: false
        }
      };

    case CASE_CLOSE_FAIL:
      return {
        ...state,
        deletingCase: {
          isLoading: false
        }
      };

    case REQUEST_UPLOAD_CASE_PHOTO:
      return {
        ...state,
        uploadingCasePhoto: {
          isLoading: true
        }
      };

    case UPLOAD_CASE_PHOTO_SUCCESS:
      return {
        ...state,
        uploadingCasePhoto: {
          isLoading: false
        }
      };

    case UPLOAD_CASE_PHOTO_FAIL:
      return {
        ...state,
        uploadingCasePhoto: {
          isLoading: false
        }
      };

    case VEHICLE_CASE_UPDATE_REQUEST:
      return {
        ...state,
        updateCase: {
          isLoading: true
        }
      };
    case VEHICLE_CASE_UPDATE_SUCCESS:
      return defaultStateVehicleUpdate;

    case VEHICLE_CASE_UPDATE_FAIL:
      return defaultStateVehicleUpdate;

    case MARKETS_ALLOWED_REQUEST:
      return {
        ...state
      };
    case MARKETS_ALLOWED_SUCCESS:
      return {
        ...state,
        marketsAllowed: action.payload
      };
    case MARKETS_ALLOWED_FAIL:
      return {
        ...state,
        marketsAllowed: []
      };

    case FAV_COUNT_SUCCESS:
      return {
        ...state,
        favoritesCount: action.payload
      };
    case AWARD_BYCASE_REQUEST:
      return defaultAwardState;
    case AWARD_BYCASE_SUCCESS:
      return {
        ...state,
        awardDetail: action.payload,
        request: true
      };
    case AWARD_BYCASE_FAIL:
      return defaultAwardState;
    case AWARD_BYCASE_CLEAN:
      return defaultAwardState;

    case POST_BDELIVERY_REQUEST:
      return {
        ...state,
        orderDownload: {
          isLoading: true
        }
      };

    case POST_BDELIVERY_SUCCESS:
      return {
        ...state,
        orderDownload: {
          isLoading: false
        }
      };

    case POST_BDELIVERY_FAIL:
      return {
        ...state,
        orderDownload: {
          isLoading: false
        }
      };

    case GET_INVENTORY_HISTORY_REQUEST:
      return {
        ...state,
        currentCaseInventoryHistory: { data: false, isLoading: true }
      };

    case GET_INVENTORY_HISTORY_SUCCESS:
      return {
        ...state,
        currentCaseInventoryHistory: {
          ...state.currentCaseInventoryHistory,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_INVENTORY_HISTORY_FAIL:
      return {
        ...state,
        currentCaseInventoryHistory: defaultState
      };

    case CLEAN_INCOMING_DETAIL:
      return {
        ...state,
        currentCaseIncomingInventory: undefined
      };

    case SAVE_VIDEO_URL_REQUEST:
      return {
        ...state,
        videoUrl: {
          isLoading: true,
        }
      };
    
    case SAVE_VIDEO_URL_SUCCESS:
      return {
        ...state,
        videoUrl: {
          isLoading: false,
        }
      };

    case SAVE_VIDEO_URL_FAIL:
      return {
        ...state,
        videoUrl: {
          isLoading: false,
        }
      };
    
    case GET_VIDEO_URL_REQUEST:
      return {
        ...state,
        videoUrl: {
          isLoading: true,
        }
      };

    case GET_VIDEO_URL_SUCCESS:
      return {
        ...state,
        videoUrl: {
          isLoading: false,
          data: action.payload
        }
      };

    case GET_VIDEO_URL_FAIL:
      return {
        ...state,
        videoUrl: {
          isLoading: false,
          data: null
        }
      };

    case DELETE_VIDEO_URL_REQUEST:
      return {
        ...state,
        videoUrl: {
          ...state.videoUrl,
          isLoading: true,
        }
      };
    
    case DELETE_VIDEO_URL_SUCCESS:
      return {
        ...state,
        videoUrl: {
          isLoading: false,
          data: null
        }
      };

    case DELETE_VIDEO_URL_FAIL:
      return {
        ...state,
        videoUrl: {
          isLoading: false,
        }
      };
    
    case GET_CASE_LOGS_REQUEST:
      return {
        ...state,
        caseLogs: {
          data: {},
          isLoading: true
        }
      };
    
    case GET_CASE_LOGS_SUCCESS:
      return {
        ...state,
        caseLogs: {
          data: action.payload,
          isLoading: false
        }
      };

    case GET_CASE_LOGS_FAIL:
      return {
        ...state,
        caseLogs: {
          data: {},
          isLoading: false
        }
      };

      default:
      return state;
  }
}
export default caseReducer;
