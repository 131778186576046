import {
  ADD_PHOTO_RECOLECTION,
  DELETE_PHOTO_RECOLECTION,
  CLEAR_PHOTOS_RECOLECTION,
  ADD_PHOTO_DELIVERED,
  DELETE_PHOTO_DELIVERED,
  SET_RECOLECTION_PICS_LOADING,
  RECOLECTION_PICS_SUCCESS,
  RECOLECTION_PICS_FAIL,
  SET_DELIVERED_PICS_LOADING,
  DELIVERED_PICS_SUCCESS,
  DELIVERED_PICS_FAIL,
  MARK_AS_FAVORITE_DELIVERY,
  MARK_AS_FAVORITE_COLLECTED,
  GET_TRANSFER_DETAIL_REQUEST,
  GET_TRANSFER_DETAIL_SUCCESS,
  GET_TRANSFER_DETAIL_FAIL,
  SAVE_TRANSFER_FILTER,
  CHANGE_TRANSFER_PICS
} from '../actions/transferActions';

let initial = {};
function transferReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {
    case SET_RECOLECTION_PICS_LOADING:
      return {
        ...state,
        recolectedPhotos: {
          isLoading: action.payload
        }
      };

    case RECOLECTION_PICS_SUCCESS:
      return {
        ...state,
        recolectedPhotos: {
          isLoading: false,
          data: action.payload.data,
          movementId: action.payload.movementId
        }
      };

    case RECOLECTION_PICS_FAIL:
      return {
        ...state,
        recolectedPhotos: {
          isLoading: false
        }
      };

    case SET_DELIVERED_PICS_LOADING:
      return {
        ...state,
        deliveredPhotos: {
          isLoading: action.payload
        }
      };

    case DELIVERED_PICS_SUCCESS:
      return {
        ...state,
        deliveredPhotos: {
          isLoading: false,
          data: action.payload.data,
          movementId: action.payload.movementId
        }
      };

    case DELIVERED_PICS_FAIL:
      return {
        ...state,
        deliveredPhotos: {
          isLoading: false
        }
      };

    case ADD_PHOTO_RECOLECTION:
      return {
        ...state,
        recolectedPhotos: {
          ...state.recolectedPhotos,
          data: [...state.recolectedPhotos.data, action.photo]
        }
      };

    case DELETE_PHOTO_RECOLECTION:
      return {
        ...state,
        recolectedPhotos: {
          ...state.recolectedPhotos,
          data: [...state.recolectedPhotos.data.filter(ph => ph.id !== action.id)]
        }
      };

    case ADD_PHOTO_DELIVERED:
      return {
        ...state,
        deliveredPhotos: {
          ...state.deliveredPhotos,
          data: [...state.deliveredPhotos.data, action.photo]
        }
      };

    case DELETE_PHOTO_DELIVERED:
      return {
        ...state,
        deliveredPhotos: {
          ...state.deliveredPhotos,
          data: [...state.deliveredPhotos.data.filter(ph => ph.id !== action.fileId)]
        }
      };

    case CLEAR_PHOTOS_RECOLECTION:
      return {
        ...state,
        recolectedPhotos: { isLoading: false, data: [] },
        deliveredPhotos: { isLoading: false, data: [] }
      };

    case MARK_AS_FAVORITE_DELIVERY:
      // console.log('action.payload :>> ', action.payload);
      return {
        ...state,
        deliveredPhotos: {
          ...state.deliveredPhotos,
          data: action.payload
        }
      };

    case MARK_AS_FAVORITE_COLLECTED:
      return {
        ...state,
        recolectedPhotos: {
          ...state.recolectedPhotos,
          data: action.payload
        }
      };

    case GET_TRANSFER_DETAIL_REQUEST:
      return {
        isLoading: true,
        data: false
      };
    case GET_TRANSFER_DETAIL_SUCCESS:
      return {
        isLoading: false,
        data: action.payload
      };
    case GET_TRANSFER_DETAIL_FAIL:
      return {
        isLoading: false,
        data: false
      };
    case SAVE_TRANSFER_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case CHANGE_TRANSFER_PICS:
      const { newRecolectedPhotos, newDeliveredPhotos } = action.payload;
      return {
        ...state,
        recolectedPhotos: {
          ...state.recolectedPhotos,
          data : newRecolectedPhotos
        },
        deliveredPhotos: {
          ...state.deliveredPhotos,
          data : newDeliveredPhotos
        }
      }

    default:
      return {
        ...state
      };
  }
}
export default transferReducer;
