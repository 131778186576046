import {
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_FAIL,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_PREVIEW_REQUEST,
  DOWNLOAD_FILE_PREVIEW_SUCCESS,
  DOWNLOAD_FILE_PREVIEW_FAIL,
  BACKGROUND_DOWNLOAD_SUCCESS
} from '../actions/downloadFile';

let initial = {};

function downloadReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {
    case DOWNLOAD_FILE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        queue: state.queue ? [...state.queue, action.payload] : [action.payload],
        isLoading: false
      };

    case DOWNLOAD_FILE_FAIL:
      return {
        ...state,
        isLoading: false
      };

    case BACKGROUND_DOWNLOAD_SUCCESS:
      return {
        ...state,
        queue: action.payload
      };
    case DOWNLOAD_FILE_PREVIEW_REQUEST:
      return {
        ...state,
        [action.payload.fileId]: { isLoading: true, fileId: action.payload.fileId }
      };
    case DOWNLOAD_FILE_PREVIEW_SUCCESS:
      return { ...state, [action.payload.fileId]: { ...action.payload, isLoading: false } };
    case DOWNLOAD_FILE_PREVIEW_FAIL:
      return { ...state, [action.payload.fileId]: { isLoading: false } };

    default:
      return state;
  }
}
export default downloadReducer;
