import { LOCATIONS_REQUEST, LOCATIONS_SUCCESS, LOCATIONS_FAIL } from '../actions/catalogs2Actions';

function catalogs2Reducer(state = {}, action) {
  switch (action.type) {
    case LOCATIONS_REQUEST:
      return {
        ...state,
        locationTypes: {
          isLoading: true
        }
      };

    case LOCATIONS_SUCCESS:
      return {
        ...state,
        locationTypes: {
          isLoading: false,
          data: action.payload
        }
      };

    case LOCATIONS_FAIL:
      return {
        ...state,
        locationTypes: {
          isLoading: false,
          data: []
        }
      };

    default:
      return state;
  }
}

export default catalogs2Reducer;
