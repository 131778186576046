import {
  INSURED_REQUEST_SEARCH,
  INSURED_SEARCH_SUCCESS,
  INSURED_SEARCH_FAIL,
  CLEAN_SEARCH_EXTERNAL
} from '../actions/insuredExternalActions';
let initial = {};
function insuredReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }
  const defaultState = {
    ...state,
    isLoading: false
  };

  switch (action.type) {
    case INSURED_REQUEST_SEARCH:
      return {
        isLoading: true
      };
    case INSURED_SEARCH_SUCCESS:
      return {
        isLoading: false,
        data: action.data
      };
    case INSURED_SEARCH_FAIL:
      return defaultState;
    case CLEAN_SEARCH_EXTERNAL:
      return defaultState;
    default:
      return state;
  }
}
export default insuredReducer;
