import {
  USER_ADD_REQUEST,
  USER_ADD_FAIL,
  USER_ADD_SUCCESS,
  USER_ADD_FILTER,
  PROOF_GET_REQUEST,
  PROOF_GET_SUCCESS,
  PROOF_ADD_REQUEST,
  PROOF_ADD_SUCCESS,
  PROOF_ADD_FAIL,
  PROOF_DELETE_REQUEST,
  PROOF_DELETE_SUCCESS,
  PROOF_DELETE_FAIL,
  PROOF_GET_FAIL,
  PROOF_TYPE_CHANGE_REQUEST,
  PROOF_TYPE_CHANGE_SUCCESS,
  PROOF_TYPE_CHANGE_FAIL,
  GET_BUYER_ASSOCIATED_USERS_REQUEST,
  GET_BUYER_ASSOCIATED_USERS_SUCCESS,
  GET_BUYER_ASSOCIATED_USERS_FAIL,
  BUYER_ASSOCIATED_USERS_REQUEST,
  BUYER_ASSOCIATED_USERS_SUCCESS,
  BUYER_ASSOCIATED_USERS_FAIL,
  BUYER_ASSOCIATED_USERS_UPDATE_REQUEST,
  BUYER_ASSOCIATED_USERS_UPDATE_SUCCESS,
  BUYER_ASSOCIATED_USERS_UPDATE_FAIL,
  USER_GET_COMMENTS_REQUEST,
  USER_GET_COMMENTS_SUCCESS,
  USER_GET_COMMENTS_FAIL
} from '../actions/usersActions';
let initial = { isLoading: false, response: undefined };
function userReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {
    case USER_ADD_REQUEST:
      return {
        isLoading: true
      };
    case USER_ADD_SUCCESS:
      return {
        isLoading: false,
        response: action.response
      };
    case USER_ADD_FAIL:
      return { ...state, isLoading: false };
    case USER_ADD_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case PROOF_GET_REQUEST:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: true,
          data: []
        }
      };
    case PROOF_GET_SUCCESS:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: false,
          data: action.payload
        }
      };
    case PROOF_GET_FAIL:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: false,
          data: []
        }
      };
    case PROOF_ADD_REQUEST:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: true
        }
      };
    case PROOF_ADD_SUCCESS:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: false,
          data: action.payload
        }
      };
    case PROOF_ADD_FAIL:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: false
        }
      };
    case PROOF_DELETE_REQUEST:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: true
        }
      };
    case PROOF_DELETE_SUCCESS:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: false,
          data: action.payload
        }
      };
    case PROOF_DELETE_FAIL:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: false
        }
      };
    case PROOF_TYPE_CHANGE_REQUEST:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: true
        }
      };
    case PROOF_TYPE_CHANGE_SUCCESS:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: false,
          data: action.payload
        }
      };
    case PROOF_TYPE_CHANGE_FAIL:
      return {
        ...state,
        proofAddress: {
          ...state.proofAddress,
          isLoading: false
        }
      };
    case GET_BUYER_ASSOCIATED_USERS_REQUEST:
      return {
        ...state,
        associatedUsers: {
          data: [],
          isLoading: true
        }
      };
    case GET_BUYER_ASSOCIATED_USERS_SUCCESS:
      return {
        ...state,
        associatedUsers: {
          data: action.payload,
          isLoading: false
        }
      };
    case GET_BUYER_ASSOCIATED_USERS_FAIL:
      return {
        ...state,
        associatedUsers: {
          data: [],
          isLoading: false
        }
      };
    case BUYER_ASSOCIATED_USERS_REQUEST:
      return {
        ...state,
        associatedUsers: {
          ...state.associatedUsers,
          isLoading: true
        }
      };
    case BUYER_ASSOCIATED_USERS_SUCCESS:
      return {
        ...state,
        associatedUsers: {
          ...state.associatedUsers,
          isLoading: false
        }
      };
    case BUYER_ASSOCIATED_USERS_FAIL:
      return {
        ...state,
        associatedUsers: {
          ...state.associatedUsers,
          isLoading: false
        }
      };
    case BUYER_ASSOCIATED_USERS_UPDATE_REQUEST:
      return {
        ...state,
        associatedUsers: {
          ...state.associatedUsers,
          isLoading: true
        }
      };
    case BUYER_ASSOCIATED_USERS_UPDATE_SUCCESS:
      return {
        ...state,
        associatedUsers: {
          ...state.associatedUsers,
          isLoading: false
        }
      };
    case BUYER_ASSOCIATED_USERS_UPDATE_FAIL:
      return {
        ...state,
        associatedUsers: {
          ...state.associatedUsers,
          isLoading: false
        }
      };
    case USER_GET_COMMENTS_REQUEST:
      return {
        ...state,
        userComments: {
          data: [],
          isLoading: true
        }
      };
    case USER_GET_COMMENTS_SUCCESS:
      return {
        ...state,
        userComments: {
          data: action.payload,
          isLoading: false
        }
      };
    case USER_GET_COMMENTS_FAIL:
      return {
        ...state,
        userComments: {
          data: [],
          isLoading: false
        }
      };
    default:
      return state;
  }
}
export default userReducer;
