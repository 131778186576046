export default {
  layout: {
    drawerIsVisible: false
  },
  notificationUser: {
    visible: false
  },
  awardings: {
    listSearchResult: { data: [], isSearching: false },
    unitarySearchResult: {
      search: { isAdvanced: false, uniqueAdverId: false, query: {} },
      data: [],
      isSearching: false,
      viewType: 0
    },
    awardHistory: { data: false, isLoading: false },
    filters : {
      byList : {
        isFilter: false,
        filters: null
      },
      byUnitary : {
        isFilter: false,
        filters: null
      }
    }
  },
  catalogs: {
    countries: { data: [], isLoading: false },
    languages: { data: [], isLoading: false },
    timeZones: { data: [], isLoading: false },
    catalogDetail: { isLoading: false, data: false },
    actionCatalog: { isLoading: false },
    filter: {
      typeCatalog: '',
      serviceType: '',
      provider: '',
      isFilter: false,
      pageNumberMarker: 1,
      pageNumberProvider: 1,
      pageNumberProviderOffice: 1
    },
    cancelReasons: { data: [], isLoading: false },
    otherSubWreckTypes : { data: [], isLoading: false },
  },
  components: {
    type: {
      floatingMenu: []
    },
    isLoading: false
  },
  adverts: {
    actions: {
      isNotifyingBuyers: false,
      isPostingDeliveryComment: false
    },
    activeAdvert: {
      data: {},
      isLoading: false
    },
    advertsInfo: {
      advertId: '',
      isPosting: false
    },
    casesSelected: [],
    changeStatusAdvert: {
      isLoading: false
    },
    currentAdvertDetail: {
      data: false,
      isLoading: false
    },
    currentAdvertResult: {
      data: false,
      isLoading: false
    },
    historyAdverts: {
      data: false,
      isLoading: false
    },
    preAdverts: {},
    searchCases: { isLoading: false, data: false },
    reportResults: { isLoading: false, data: false },
    filter: {
      isSearchAdvanced: true,
      name: '',
      publicationid: '',
      insCarrierId: [],
      startDate: '',
      endDate: '',
      isFilter: false,
      pageNumber: 1
    }
  },
  auth: {
    isAuthenticated: false,
    isSubmitting: false
  },
  buyers: {
    vouchers: {
      isUploading: false,
      isDownloading: false,
      isUpdating: false,
      data: [],
      currentVoucher: {
        isLoading: false,
        data: {}
      },
      rejectComment: {
        isLoading: false,
        data: ''
      }
    },
    bidAdvert: {
      isLoading: false
    },
    currentAdvertDetail: {
      data: false,
      isLoading: false
    },
    currentAdvertComments:{
      data: [],
      isLoading: false
    },
    searchResults: {
      data: [],
      isSearching: false
    },
    detailVisited: false,
    awardingsResults: {
      data: [],
      isSearching: false
    },
    detailOnlineVisited: false,
    detailArchivedVisited: false,
    downloadFile:{
      queue: [],
      isLoading: false
    },
    currentAwardingDetail: {
      isLoading: false,
      data: false
    },
    buyerInvitation: {
      isLoading: false
    },
    deliveryDetail: {
      isLoading: false,
      data: undefined
    },
    filter: {
      isSearchAdvanced: true,
      PaymentStatus:null,
      caseType: 1,
      vehicleTypeId: '',
      insCarrierId: [],
      brand: false,
      model: '',
      engineTypeId: '',
      stateId: '',
      favorite: '',
      bidded: '',
      isFilter: false,
      wildcard: '',
      offerTypeId: '',
      damageType: ''
    },
    modals:{
      advertCardModal: false,
      advertDetailModal: false,
      lastUpdatedAdvert: null,
      lastDetailVisited: null
    },
    images:{
      adverts: {},
    }
  },
  case: {
    audatexPics: {
      audatexPicsExist: false,
      blockRequest: false,
      data: [],
      isLoading: false
    },
    deliveryInfo: {
      isLoading: false
    },
    checkedPhotos: [],
    chkMarkets: [],
    marketsAllowed: [],
    modalPage: 1,
    photos: {
      data: [],
      isLoading: false
    },
    photosInfo: [],
    updateCase: {
      isLoading: false
    },
    uploadingCasePhoto: {
      isLoading: false
    },
    wasDownloaded: false,
    videoUrl:{
      isLoading: false,
      data: null
    },
    caseLogs:{
      data: {},
      isLoading: false
    }
  },
  caseDamages : {
    damageComments:{
      isLoading: false,
      data: null
    }
  },
  insuredExternal: {},
  searchCases: {},
  transfers: {
    deliveredPhotos: [],
    recolectedPhotos: [],
    filter: {
      isSearchAdvanced: false,
      isFilter: false,
      statusId: '',
      carPounds: '',
      carPoundBranchId: '',
      startDate: null,
      endDate: null,
      stateId: [],
      caseNumber: '',
      vin: '',
      insCarrierId: [],
      craneProviderId: [],
      craneBranchId: '',
      incidence: '',
      Wildcard: ''
    }
  },
  user: {
    sideBar: { isCollapsed: true },
    info: { groupName: null, publicName: null },
    password: {
      isChanging: false
    }
  },
  users: {
    filter: {
      groupId: 0,
      name: '',
      marketId: [],
      statusId: 0,
      startDate: '',
      endDate: '',
      PageNumber: 0,
      isFilter: false,
      isAdvanced: false
    },
    proofAddress : {
      isLoading : false,
      data : [],
      isUploading : false,
    },
    associatedUsers:{
      data: [],
      isLoading: false
    },
    userComments:{
      data: [],
      isLoading: false
    },
  },
  wizardForm: {
    currentStep: 0,
    imagesInfo: []
  },
  attachments: { documents: [] },
  search: {
    isSearching: false,
    params: {}
  },
  payments: {
    searchPaymentsResults: [],
    paymentDetail: { isLoading: false, data: false, error: false },
    validateFiles: {
      approvedRequest: false,
      rejectedRequest: false
    }
    //paymentDetail: { data: false, isLoading: false }
  },
  files: {
    currentFileList: {
      data: undefined,
      isLoading: false
    },
    caseDocuments: {
      data: undefined,
      isLoading: false
    },
    fileResults: { isLoading: false, data: [],file:null },
  },
  inventoryPhotos: {
    incoming: { data: [] },
    checkout: { data: [] }
  },
  vehicleIncomingRegister: {
    isPosting: false
  },
  managmentFiles: { files: [] },
  inventoryReports: { data: {}, filters: {} }
};
