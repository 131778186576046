import { SET_DRAWER_VISIBLE } from '../actions/layoutActions';

let initial = {};
function setDrawerIsVisibleReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }
  switch (action.type) {
    case SET_DRAWER_VISIBLE:
      return { ...state, drawerIsVisible: action.payload };
    default:
      return state;
  }
}
export default setDrawerIsVisibleReducer;
