import {
  SEARCH_ADVERTS_4_BUYERS_REQUEST,
  SEARCH_ADVERTS_4_BUYERS_SUCCESS,
  SEARCH_ADVERTS_4_BUYERS_FAIL,
  REFRESH_ADVERT_LIST_REQUEST,
  BID_ADVERT_REQUEST,
  BID_ADVERT_FAIL,
  BID_ADVERT_SUCCESS,
  BUYER_ADVERT_DETAIL_REQUEST,
  BUYER_ADVERT_DETAIL_SUCCESS,
  BUYER_ADVERT_DETAIL_FAIL,
  AWARDINGS_SEARCH_REQUEST,
  AWARDINGS_SEARCH_SUCCESS,
  AWARDINGS_SEARCH_FAIL,
  BUYER_ADVERT_ONLINE_DETAIL_VISITED,
  BUYER_ADVERT_ARCHIVED_DETAIL_VISITED,
  AWARDINGS_DETAIL_REQUEST,
  AWARDINGS_DETAIL_SUCCESS,
  AWARDINGS_DETAIL_FAIL,
  BUYER_INVITATION_REQUEST,
  BUYER_INVITATION_SUCCESS,
  BUYER_INVITATION_FAIL,
  GET_VOUCHERS_REQUEST,
  GET_VOUCHERS_SUCCESS,
  GET_VOUCHERS_FAIL,
  DELETE_VOUCHER_REQUEST,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAIL,
  ADD_VOUCHER_REQUEST,
  ADD_VOUCHER_FAIL,
  ADD_VOUCHER_SUCCESS,
  VIEW_VOUCHER_REQUEST,
  VIEW_VOUCHER_SUCCESS,
  VIEW_VOUCHER_FAIL,
  SHOW_REJECT_COMMENTS_REQUEST,
  SHOW_REJECT_COMMENTS_SUCCESS,
  SHOW_REJECT_COMMENTS_FAIL,
  UPDATE_VOUCHER_FAIL,
  UPDATE_VOUCHER_SUCCESS,
  AWARDINGS_DETAIL_CLEAN,
  GET_DELIVERY_REQUEST,
  GET_DELIVERY_SUCCESS,
  GET_DELIVERY_FAIL,
  POST_DELIVERY_COMMENT_REQUEST,
  POST_DELIVERY_COMMENT_SUCCESS,
  POST_DELIVERY_COMMENT_FAIL,
  GET_DELIVERY_CLEAN,
  ADVERT_CARD_MODAL_SET,
  ADVERT_DETAIL_MODAL_SET,
  LAST_UPDATED_ADVERT,
  LAST_DETAIL_VISITED,
  SET_ADVERT_IMAGES,
  GET_ADVERT_COMMENTS_REQUEST,
  GET_ADVERT_COMMENTS_SUCCESS,
  GET_ADVERT_COMMENTS_FAIL
} from '../actions/buyersActions';
import { AWARDINGS_BYADVERT_FAIL } from 'redux/actions/awardingsActions';
import { UPDATE_ADVERT_REQUEST } from 'redux/actions/advertActions';
import { SAVE_BUYER_FILTER } from 'redux/actions/advertActions';
let initial = {};

function advertReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  const bidAdvertState = {
    ...state,
    bidAdvert: {
      isLoading: false
    }
  };

  switch (action.type) {
    case POST_DELIVERY_COMMENT_REQUEST:
      return {
        ...state,
        actions: {
          ...state.actions,
          isPostingDeliveryComment: true
        }
      };

    case POST_DELIVERY_COMMENT_SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          isPostingDeliveryComment: false
        }
      };

    case POST_DELIVERY_COMMENT_FAIL:
      return {
        ...state,
        actions: {
          ...state.actions,
          isPostingDeliveryComment: false
        }
      };

    case GET_DELIVERY_REQUEST:
      return {
        ...state,
        deliveryDetail: {
          ...state.deliveryDetail,
          isLoading: true
        }
      };

    case GET_DELIVERY_SUCCESS:
      return {
        ...state,
        deliveryDetail: {
          ...state.deliveryDetail,
          data: action.payload,
          isLoading: false
        }
      };

    case GET_DELIVERY_FAIL:
      return {
        ...state,
        deliveryDetail: {
          ...state.deliveryDetail,
          data: 'ERROR',
          isLoading: false
        }
      };

    case GET_DELIVERY_CLEAN:
      return {
        ...state,
        deliveryDetail: {
          ...state.deliveryDetail,
          data: undefined,
          isLoading: false
        }
      };

    case UPDATE_ADVERT_REQUEST:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          rejectComment: {
            ...state.vouchers.rejectComment,
            isUpdating: true
          }
        }
      };

    case UPDATE_VOUCHER_SUCCESS:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          rejectComment: {
            ...state.vouchers.rejectComment,
            isUpdating: false
          }
        }
      };

    case UPDATE_VOUCHER_FAIL:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          rejectComment: {
            ...state.vouchers.rejectComment,
            isUpdating: false
          }
        }
      };

    case SHOW_REJECT_COMMENTS_REQUEST:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          rejectComment: {
            ...state.vouchers.rejectComment,
            isLoading: true
          }
        }
      };

    case SHOW_REJECT_COMMENTS_SUCCESS:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          rejectComment: {
            ...state.vouchers.rejectComment,
            data: action.payload,
            isLoading: false
          }
        }
      };

    case SHOW_REJECT_COMMENTS_FAIL:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          rejectComment: {
            ...state.vouchers.rejectComment,
            isLoading: false
          }
        }
      };

    case VIEW_VOUCHER_REQUEST:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          currentVoucher: {
            ...state.vouchers.currentVoucher,
            isLoading: true
          }
        }
      };

    case VIEW_VOUCHER_SUCCESS:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          currentVoucher: {
            ...state.vouchers.currentVoucher,
            isLoading: false,
            data: action.payload
          }
        }
      };

    case VIEW_VOUCHER_FAIL:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          currentVoucher: {
            ...state.vouchers.currentVoucher,
            isLoading: false
          }
        }
      };

    case ADD_VOUCHER_REQUEST:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isUploading: true
        }
      };

    case ADD_VOUCHER_SUCCESS:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isUploading: false
        }
      };

    case ADD_VOUCHER_FAIL:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isUploading: false
        }
      };

    case DELETE_VOUCHER_REQUEST:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isDeleting: true
        }
      };

    case DELETE_VOUCHER_SUCCESS:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isDeleting: false,
          data: [...state.vouchers.data.filter(voucher => voucher.id !== action.payload.fileId)]
        }
      };

    case DELETE_VOUCHER_FAIL:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isDeleting: false
        }
      };

    case GET_VOUCHERS_REQUEST:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isLoading: true
        }
      };

    case GET_VOUCHERS_SUCCESS:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isLoading: false,
          data: action.payload
        }
      };

    case GET_VOUCHERS_FAIL:
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          isLoading: false
        }
      };

    case REFRESH_ADVERT_LIST_REQUEST:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          isSearching: true
        }
      };

    case SEARCH_ADVERTS_4_BUYERS_REQUEST:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          isSearching: true
        }
      };

    case SEARCH_ADVERTS_4_BUYERS_SUCCESS:
      return {
        ...state,
        searchResults: {
          isSearching: false,
          data: action.payload.data,
          querySearch: action.payload.querySearch
        },
        filter: action.payload.querySearch
      };

    case SEARCH_ADVERTS_4_BUYERS_FAIL:
      return {
        ...state,
        searchResults: {
          isSearching: false
        }
      };

    case BID_ADVERT_REQUEST:
      return {
        ...state,
        bidAdvert: {
          isLoading: true
        }
      };
    case BID_ADVERT_SUCCESS:
      return bidAdvertState;
    case BID_ADVERT_FAIL:
      return bidAdvertState;

    case BUYER_ADVERT_DETAIL_REQUEST:
      return {
        ...state,
        currentAdvertDetail: {
          ...state.currentAdvertDetail,
          isLoading: true,
          data: false
        }
      };

    case BUYER_ADVERT_DETAIL_SUCCESS:
      return {
        ...state,
        currentAdvertDetail: {
          isLoading: false,
          data: action.payload
        }
      };

    case BUYER_ADVERT_DETAIL_FAIL:
      return {
        ...state,
        currentAdvertDetail: {
          isLoading: false,
          data: false
        }
      };

    case BUYER_ADVERT_ONLINE_DETAIL_VISITED:
      return {
        ...state,
        detailOnlineVisited: action.payload,
        detailArchivedVisited: false
      };
    case BUYER_ADVERT_ARCHIVED_DETAIL_VISITED:
      return {
        ...state,
        detailArchivedVisited: action.payload,
        detailOnlineVisited: false
      };

    case AWARDINGS_SEARCH_REQUEST:
      return {
        ...state,
        awardingsResults: {
          isSearching: true,
          data: []
        }
      };

    case AWARDINGS_SEARCH_SUCCESS:
      return {
        ...state,
        awardingsResults: {
          isSearching: false,
          data: action.payload.result,
          querySearch: action.payload.requestData,
          totalPages: action.payload.totalPages,
        }
      };

    case AWARDINGS_SEARCH_FAIL:
      return {
        ...state,
        awardingsResults: {
          isSearching: false,
          data: []
        }
      };

    case AWARDINGS_BYADVERT_FAIL:
      return {
        ...state,
        awardingsResults: {
          isSearching: false,
          data: []
        }
      };

    case AWARDINGS_DETAIL_REQUEST:
      return {
        ...state,
        currentAwardingDetail: {
          isLoading: true
        }
      };

    case AWARDINGS_DETAIL_SUCCESS:
      return {
        ...state,
        currentAwardingDetail: {
          isLoading: false,
          data: action.payload
        }
      };

    case AWARDINGS_DETAIL_FAIL:
      return {
        ...state,
        currentAwardingDetail: {
          isLoading: false,
          data: 'ERROR'
        }
      };

    case AWARDINGS_DETAIL_CLEAN:
      return {
        ...state,
        currentAwardingDetail: {
          isLoading: false
        }
      };

    case BUYER_INVITATION_REQUEST:
      return {
        ...state,
        buyerInvitation: {
          isLoading: true
        }
      };

    case BUYER_INVITATION_SUCCESS:
      return {
        ...state,
        buyerInvitation: {
          isLoading: false
        }
      };

    case BUYER_INVITATION_FAIL:
      return {
        ...state,
        buyerInvitation: {
          isLoadingisSendig: false
        }
      };
    case SAVE_BUYER_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case ADVERT_CARD_MODAL_SET:
      return {
        ...state,
        modals:{
          ...state.modals,
          advertCardModal: action.payload
        }
      };
    case ADVERT_DETAIL_MODAL_SET:
      return {
        ...state,
        modals:{
          ...state.modals,
          advertDetailModal: action.payload
        }
      };
    case LAST_UPDATED_ADVERT:
      return {
        ...state,
        modals:{
          ...state.modals,
          lastUpdatedAdvert: action.payload
        }
      };
    case LAST_DETAIL_VISITED:
      return {
        ...state,
        modals:{
          ...state.modals,
          lastDetailVisited: action.payload
        }
      }
    case SET_ADVERT_IMAGES:
      return {
        ...state,
        images:{
          ...state.images,
          adverts: action.payload
        }
      }
    case GET_ADVERT_COMMENTS_REQUEST:
      return {
        ...state,
        currentAdvertComments:{
          isLoading: true
        }
      }
    case GET_ADVERT_COMMENTS_SUCCESS:
      return {
        ...state,
        currentAdvertComments:{
          isLoading: false,
          data: action.payload
        }
      }
    case GET_ADVERT_COMMENTS_FAIL:
      return {
        ...state,
        currentAdvertComments:{
          isLoading: false,
          data: []
        }
      }
    default:
      return state;
  }
}
export default advertReducer;
