import {
  SET_WIZARD_FORM,
  SET_STEP,
  CLEAN_WIZARD_FORM,
  SET_IMAGES_INFO,
  SET_IMAGES_SIZE,
  SET_IMAGES64_CONTENT,
  SET_USER_IS_LOCK
} from '../actions/wizardFormActions';
let initial = {};
function wizardFormReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        currentStep: action.step
      };
    case SET_WIZARD_FORM:
      return {
        ...state,
        ...action.wizard
      };
    case SET_USER_IS_LOCK:
      return {
        ...state,
        userIsLock: action.userIsLock
      };
    case CLEAN_WIZARD_FORM:
      return {
        currentStep: 0
      };
    case SET_IMAGES_INFO:
      return {
        ...state,
        imagesInfo: action.imagesInfo
      };
    case SET_IMAGES_SIZE:
      return {
        ...state,
        imagesSize: action.imagesSize
      };
    case SET_IMAGES64_CONTENT:
      return {
        ...state,
        images64Content: action.images64Content
      };
    default:
      return state;
  }
}
export default wizardFormReducer;
