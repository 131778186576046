import { PAGINATION_LOAD_PAGE_REQUEST, PAGINATION_CURRENT_PAGE } from '../actions/pagination';

let initial = {};

function paginationReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {
    case PAGINATION_LOAD_PAGE_REQUEST:
      return {
        ...state,
        pagination: {}
      };
    case PAGINATION_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload.currentPage
      };

    default:
      return state;
  }
}
export default paginationReducer;
