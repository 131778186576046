import {
  AWARDINGS_BYADVERT_REQUEST,
  AWARDINGS_BYADVERT_SUCCESS,
  AWARDINGS_BYADVERT_FAIL,
  AWARDINGS_BYLIST_FAIL,
  AWARDINGS_BYLIST_REQUEST,
  AWARDINGS_BYLIST_SUCCESS,
  AWARD_DELETE_SUCCESS,
  AWARDING_CASE_DETAIL_REQUEST,
  AWARDING_CASE_DETAIL_SUCCESS,
  AWARDING_CASE_DETAIL_FAIL,
  AWARDING_CASE_DETAIL_CLEAN,
  AWARD_BIDS_REQUEST,
  AWARD_BIDS_SUCCESS,
  AWARD_BIDS_FAIL,
  AWARD_BIDS_CLEAN,
  AWARD_REGISTER_REQUEST,
  AWARD_REGISTER_SUCCESS,
  AWARD_REGISTER_FAIL,
  AWARD_REGISTER_CLEAN,
  AWARD_VALIDATE_REQUEST,
  AWARD_VALIDATE_SUCCESS,
  AWARD_VALIDATE_FAIL,
  CLEAN_SEARCH_RESULTS,
  SET_UNITARY_AWARD,
  SET_MASSIVE_UNITARY_AWARD,
  AWARD_HISTORY_REQUEST,
  AWARD_HISTORY_SUCCESS,
  AWARD_HISTORY_FAIL,
  AWARD_HISTORY_CLEAN,
  SET_SEARCH_QUERY,
  SET_UNITARY_AWARD_ALL,
  SET_UNIQUE_ADVERT_ID,
  AWARD_UPDATE_REQUEST,
  AWARD_UPDATE_SUCCESS,
  AWARD_UPDATE_FAIL,
  SET_VIEW_TYPE,
  SET_CURRENT_DATA,
  SET_BYLIST_FILTERS,
  SET_BYUNITARY_FILTERS,
  RESET_UNITARY_FILTERS,
  RESET_BY_LIST_FILTERS
} from 'redux/actions/awardingsActions';
let initial = {};

function awardingsReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  const defaultLoading = {
    isLoading: false,
    data: false,
    error: false
  };

  const initialAwardingCaseDetail = {
    awardCaseDetail: defaultLoading
  };

  const initialAwardBid = {
    awardBids: defaultLoading
  };

  const initialAwardingPost = {
    awardingPost: defaultLoading
  };

  const initialAwardingValidate = {
    awardValidate: { isLoading: false }
  };

  switch (action.type) {
    case SET_VIEW_TYPE:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          viewType: action.payload
        }
      };

    case SET_CURRENT_DATA:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          totalItems: action.number
        }
      };

    case AWARDINGS_BYADVERT_REQUEST:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          isSearching: true
        }
      };

    case AWARDINGS_BYADVERT_SUCCESS:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          isSearching: false,
          data: action.payload
        }
      };

    case AWARDINGS_BYADVERT_FAIL:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          isSearching: false,
          data: []
        }
      };

    case SET_UNITARY_AWARD:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          data: [
            ...state.unitarySearchResult.data.map(card => {
              let newCard;
              if (card.caseId === action.payload.caseId) {
                newCard = {
                  ...card,
                  [action.payload.key]: action.payload.value
                };
              } else {
                newCard = card;
              }
              return newCard;
            })
          ]
        }
      };

    case SET_UNITARY_AWARD_ALL:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          data: [
            ...state.unitarySearchResult.data.map(card => {
              let newCard;
              if (card.caseId === action.payload.caseId) {
                let old = action.payload.card;
                newCard = { ...card, ...old };
              } else {
                newCard = card;
              }
              return newCard;
            })
          ]
        }
      };

    case SET_MASSIVE_UNITARY_AWARD:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          data: {
            ...state.unitarySearchResult,
            data :[
              ...state.unitarySearchResult.data.data.map(card => {
                let newCard;
                if (action.payload.evaluation(card)) {
                  newCard = {
                    ...card,
                    [action.payload.key]: action.payload.value
                  };
                } else {
                  newCard = card;
                }
                return newCard;
              })
            ]
          }
        }
      };

    case SET_UNIQUE_ADVERT_ID:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          search: {
            ...state.unitarySearchResult.search,
            uniqueAdverId: action.payload.uniqueAdverId
          }
        }
      };

    case AWARDINGS_BYLIST_REQUEST:
      return {
        ...state,
        listSearchResult: {
          isSearching: true
        }
      };

    case AWARDINGS_BYLIST_SUCCESS:
      return {
        ...state,
        listSearchResult: {
          isSearching: false,
          data: action.payload
        }
      };

    case AWARDINGS_BYLIST_FAIL:
      return {
        ...state,
        listSearchResult: {
          isSearching: false,
          data: []
        }
      };

    case AWARD_DELETE_SUCCESS:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          data: {
            ...state.unitarySearchResult.data,
            data: action.payload
          }
        }
      };
    case AWARDING_CASE_DETAIL_REQUEST:
      return {
        ...state,
        awardCaseDetail: {
          ...initialAwardingCaseDetail.awardCaseDetail,
          isLoading: true
        }
      };
    case AWARDING_CASE_DETAIL_SUCCESS:
      return {
        ...state,
        awardCaseDetail: {
          ...initialAwardingCaseDetail.awardCaseDetail,
          data: action.payload
        }
      };
    case AWARDING_CASE_DETAIL_FAIL:
      return {
        ...state,
        awardCaseDetail: {
          data: [],
          isLoading: false,
          error: true
        }
      };
    case AWARDING_CASE_DETAIL_CLEAN:
      return {
        ...state,
        awardCaseDetail: undefined
      };

    case AWARD_BIDS_REQUEST:
      return {
        ...state,
        awardBids: {
          ...initialAwardBid.awardBids,
          isLoading: true
        }
      };
    case AWARD_BIDS_SUCCESS:
      return {
        ...state,
        awardBids: {
          ...initialAwardBid.awardBids,
          data: action.payload
        }
      };
    case AWARD_BIDS_FAIL:
      return {
        ...state,
        awardBids: {
          data: [],
          isLoading: false,
          error: true
        }
      };
    case AWARD_BIDS_CLEAN:
      return {
        ...state,
        awardBids: undefined
      };

    case AWARD_REGISTER_REQUEST:
      return {
        ...state,
        awardingPost: {
          isLoading: true
        }
      };
    case AWARD_REGISTER_SUCCESS:
      return {
        ...state,
        awardingPost: {
          ...initialAwardingPost
        }
      };
    case AWARD_REGISTER_FAIL:
      return {
        ...state,
        awardingPost: {
          isLoading: false
        }
      };
    case AWARD_REGISTER_CLEAN:
      return {
        ...state,
        ...initialAwardingPost
      };
    case AWARD_VALIDATE_REQUEST:
      return {
        ...state,
        awardValidate: {
          isLoading: true
        }
      };
    case AWARD_VALIDATE_SUCCESS:
      return {
        ...state,
        awardValidate: {
          isLoading: false
        }
      };

    case AWARD_VALIDATE_FAIL:
      return {
        ...state,
        ...initialAwardingValidate
      };

    case CLEAN_SEARCH_RESULTS:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          isSearching: false,
          data: []
        },
        listSearchResult: {
          isSearching: false,
          data: []
        }
      };

    case AWARD_HISTORY_REQUEST:
      return {
        ...state,
        awardHistory: {
          isLoading: false,
          data: false
        }
      };

    case AWARD_HISTORY_SUCCESS:
      return {
        ...state,
        awardHistory: {
          isLoading: false,
          data: action.payload
        }
      };
    case AWARD_HISTORY_FAIL:
      return {
        ...state,
        awardHistory: {
          isLoading: false,
          data: false
        }
      };
    case AWARD_HISTORY_CLEAN:
      return {
        ...state,
        awardHistory: {
          isLoading: false,
          data: false
        }
      };

    case SET_SEARCH_QUERY:
      return {
        ...state,
        unitarySearchResult: {
          ...state.unitarySearchResult,
          search: {
            ...state.unitarySearchResult.search,
            isAdvanced: action.payload.isAdvanced,
            query: action.payload.query
          }
        }
      };

    case AWARD_UPDATE_REQUEST:
      return {
        ...state,
        awardUpdate: {
          ...defaultLoading,
          isLoading: true
        }
      };
    case AWARD_UPDATE_SUCCESS:
      return {
        ...state,
        awardUpdate: {
          ...defaultLoading,
          isLoading: false
        }
      };

    case AWARD_UPDATE_FAIL:
      return {
        ...state,
        awardUpdate: {
          ...defaultLoading,
          isLoading: false,
          error: true
        }
      };

    case SET_BYLIST_FILTERS:
      return {
        ...state,
        filters:{
          ...state.filters,
          byList : {
            isFilter : action.payload.isFilter,
            filters: action.payload.filters
          }
        }
      };
    
    case SET_BYUNITARY_FILTERS:
      return {
        ...state,
        filters:{
          ...state.filters,
          byUnitary : {
            isFilter : action.payload.isFilter,
            filters: action.payload.filters,
            advertId : action.payload.advertId
          }
        }
      };
    case RESET_UNITARY_FILTERS:
      return {
        ...state,
        filters:{
          ...state.filters,
          byUnitary : {
            isFilter : false,
            filters: undefined,
            advertId : undefined
          }
        },
        unitarySearchResult: {
          ...state.unitarySearchResult,
          search: {
            ...state.unitarySearchResult.search,
            isAdvanced: false,
            query: undefined,
            uniqueAdverId: undefined
          }
        }
      };    
    
    case RESET_BY_LIST_FILTERS:
      return {
        ...state,
        filters:{
          ...state.filters,
          byList : {
            isFilter : false,
            filters: undefined
          }
        },
        listSearchResult: {
          ...state.listSearchResult,
          search: {
            ...state.listSearchResult.search,
            isAdvanced: false,
            query: undefined
          }
        }
      };
    default:
      return state;
  }
}

export default awardingsReducer;
