import { DAMAGE_COMMENTS_FAIL, DAMAGE_COMMENTS_SUCCESS, REQUEST_DAMAGE_COMMENTS } from "redux/actions/caseDamageActions";

let initial = {};

function caseDamageReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {
    case REQUEST_DAMAGE_COMMENTS:
      return {
        ...state,
        damageComments: {
          isLoading: true,
          data: null
        }
      };
    
    case DAMAGE_COMMENTS_SUCCESS:
      return {
        ...state,
        damageComments: {
          isLoading: false,
          data: action.payload
        }
      };
    
    case DAMAGE_COMMENTS_FAIL:
      return {
        ...state,
        damageComments: {
          isLoading: false,
          data: []
        }
      };

      default:
      return state;
  }
}
export default caseDamageReducer;
