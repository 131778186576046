import { combineReducers } from 'redux';
import { LOGOUT_USER } from '../actions/authActions';
import initialStore from '../initialState';
import authReducer from './authReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import catalogsReducer from './catalogsReducer';
import wizardFormReducer from './wizardFormReducer';
import insuredExternal from './insuredReducer';
import searchCasesReducer from './searchCasesReducer';
import caseReducer from './caseReducer';
import transferReducer from './transferReducer';
import advertReducer from './advertReducer';
import downloadFile from './downloadFile';
import buyersReducer from './buyersReducer';
import paginationReducer from './paginationReducer';
import attachmentsReducer from './attachmentsReducer';
import awardingsReducer from './awardingsReducer';
import searchReducer from './searchReducer';
import paymentsReducer from './paymentsReducer';
import filesReducer from './filesReducer';
import oneCaseReducer from '../../modules/Case/redux-saga/reducers';
import transferDetail from '../../modules/Transfer/redux-saga/reducers';
import catalogs2Reducer from './catalogs2Reducer';
import compensation from '../../modules/Compensation/redux-saga/reducers';
import inventory from '../../modules/Inventory/redux-saga/reducers';
import transfersReports from 'modules/Reports/redux-saga/reducers';
import vehiclesIncoming from 'modules/VehiclesInComings/redux-saga/reducers';
import vehicleIncomingDetails from 'modules/DetailsVehicleIncoming/redux-saga/reducers';
import deliveries from './deliveriesReducer';
import incomingRegister from 'modules/RegisterVehicleIncoming/redux-sagas/reducers';
import vehicleEntries from 'modules/VehicleEntries/redux-saga/reducers';
import vehicleEntryDetails from 'modules/VehicleEntryDetails/redux-saga/reducers';
import vehicleCheckOut from 'modules/VehicleCheckOut/redux-saga/reducers';
import inventoryPhotos from './inventoryPics';
import updateVehicleEntries from 'modules/UpdateVehicleEntries/redux-sagas/reducers';
import managmentFiles from 'modules/RegisterVehicleIncoming/redux-sagas/reducers/files';
import registerOutgoingVehicle from 'modules/RegisterOutgoingVehicle/redux-saga/reducers';
import updateFiles from 'modules/UpdateVehicleEntries/redux-sagas/reducers/files';
import updateImagesEntries from 'modules/UpdateVehicleEntries/redux-sagas/reducers/images';
import isFromAdverts from 'pages/CasesDetail/redux/reducers';
import inventoryReports from 'modules/ReportsInventoryEntries/redux-saga/reducers';
import caseTransferDetail from 'modules/TransferDetails/redux-saga/reducers';
import updateOutgoingVehicle from 'modules/UpdateOutgoingVehicle/redux-sagas/reducers';
import updateCaseTransfer from 'modules/TransferUpdate/redux-saga/reducers';
import notifications from 'modules/Notifications/redux-saga/reducers';
import logs from 'modules/EventLog/redux-saga/reducers';
import setDrawerIsVisibleReducer from './layoutReducer';
import componentReducer from './componentReducer';

import message from 'components/common/TopBar/Message/reducer';
import caseDamageReducer from './caseDamageReducer';

const appReducer = combineReducers({
  layout: setDrawerIsVisibleReducer,
  adverts: advertReducer,
  attachments: attachmentsReducer,
  auth: authReducer,
  awardings: awardingsReducer,
  buyers: buyersReducer,
  case: caseReducer,
  caseDamages: caseDamageReducer,
  catalogs: catalogsReducer,
  components: componentReducer,
  downloadFile: downloadFile,
  insuredExternal: insuredExternal,
  pagination: paginationReducer,
  search: searchReducer,
  searchCases: searchCasesReducer,
  transfers: transferReducer,
  user: userReducer,
  users: usersReducer,
  wizardForm: wizardFormReducer,
  payments: paymentsReducer,
  files: filesReducer,
  oneCase: oneCaseReducer,
  catalogs2: catalogs2Reducer,
  transferDetail,
  compensation,
  inventory,
  reports: transfersReports,
  deliveries: deliveries,
  vehiclesIncoming,
  vehicleIncomingDetails,
  vehicleIncomingRegister: incomingRegister,
  vehicleEntries,
  vehicleEntryDetails,
  vehicleCheckOut,
  inventoryPhotos,
  updateVehicleEntries,
  managmentFiles,
  registerOutgoingVehicle,
  updateFiles,
  updateImagesEntries,
  isFromAdverts,
  inventoryReports,
  caseTransferDetail,
  updateOutgoingVehicle,
  updateCaseTransfer,
  notifications,
  logs,
  message
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = initialStore;
  }
  return appReducer(state, action);
};

export default rootReducer;
