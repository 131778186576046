import {
  DELIVERY_INFO_ADMINS_REQUEST,
  DELIVERY_INFO_ADMINS_SUCCESS,
  DELIVERY_INFO_ADMINS_FAIL,
  DELIVERY_ORDER_DOWNLOAD_FAIL,
  DELIVERY_ORDER_DOWNLOAD_REQUEST,
  DELIVERY_ORDER_DOWNLOAD_SUCCESS
} from '../actions/deliveries';

let initial = {};

function deliveries(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {
    case DELIVERY_INFO_ADMINS_REQUEST:
      return {
        isLoading: true
      };
    case DELIVERY_INFO_ADMINS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };

    case DELIVERY_INFO_ADMINS_FAIL:
      return {
        isLoading: false
      };

    case DELIVERY_ORDER_DOWNLOAD_REQUEST:
      return {
        ...state,
        download: {
          isLoading: true
        }
      };
    case DELIVERY_ORDER_DOWNLOAD_SUCCESS:
      return {
        ...state,
        download: {
          isLoading: false,
          data: action.payload
        }
      };

    case DELIVERY_ORDER_DOWNLOAD_FAIL:
      return {
        ...state,
        download: {
          isLoading: false
        }
      };

    default:
      return state;
  }
}
export default deliveries;
