import {
  REQUEST_CASE_STATUS,
  REQUEST_CASE_TYPE,
  CASE_STATUS_SUCCESS,
  CASE_TYPE_SUCCESS,
  CASE_STATUS_FAIL,
  CASE_TYPE_FAIL
} from '../actions/searchCases';

let initial = {};
function searchCasesReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }
  switch (action.type) {
    case REQUEST_CASE_TYPE:
      return {
        ...state,
        caseTypes: {
          isLoading: true
        }
      };
    case CASE_TYPE_SUCCESS:
      return {
        ...state,
        caseTypes: {
          isLoading: false,
          data: action.caseTypes
        }
      };
    case CASE_TYPE_FAIL:
      return {
        ...state,
        caseTypes: {
          isLoading: false
        }
      };
    case REQUEST_CASE_STATUS:
      return {
        ...state,
        caseStatus: {
          isLoading: true
        }
      };
    case CASE_STATUS_SUCCESS:
      return {
        ...state,
        caseStatus: {
          isLoading: false,
          data: action.caseStatus
        }
      };

    case CASE_STATUS_FAIL:
      return {
        caseStatus: {
          isLoading: false
        }
      };
    default:
      return state;
  }
}
export default searchCasesReducer;
