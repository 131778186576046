import {
  REQUEST_SEARCH_ADVERTS,
  SEARCH_ADVERTS_SUCCESS,
  SEARCH_ADVERTS_FAIL,
  ACTIVE_ADVERT_REQUEST,
  ACTIVE_ADVERT_SUCCESS,
  ACTIVE_ADVERT_FAIL,
  ADVERT_HISTORY_REQUEST,
  ADVERT_HISTORY_SUCCESS,
  ADVERT_HISTORY_FAIL,
  POST_ADVERT_REQUEST,
  POST_ADVERT_SUCCESS,
  POST_ADVERT_FAIL,
  CASES_SEARCH_REQUEST,
  CASES_SEARCH_SUCCESS,
  CASES_SEARCH_CANCELLED,
  CASES_SEARCH_FAIL,
  CASES_PRE_SELECT_REQUEST,
  CASES_PRE_SELECT_SUCCESS,
  CASES_PRE_SELECT_FAIL,
  ADVERT_DETAIL_REQUEST,
  ADVERT_DETAIL_SUCCESS,
  ADVERT_DETAIL_FAIL,
  ADVERT_DETAIL_CLEAN,
  CLEAN_ADVERT_SEARCH,
  REMOVE_CASE_FROM_ADVERT_REQUEST,
  REMOVE_CASE_FROM_ADVERT_SUCCESS,
  REMOVE_CASE_FROM_ADVERT_FAIL,
  HISTORY_ADVERT_REQUEST,
  HISTORY_ADVERT_SUCCESS,
  HISTORY_ADVERT_FAIL,
  NOTIFY_BUYERS_REQUEST,
  NOTIFY_BUYERS_SUCCESS,
  NOTIFY_BUYERS_FAIL,
  CLEAN_CURRENT_ADVERT_DETAIL,
  CLEAN_HISTORY_ADVERT,
  CHANGE_ADVERT_STATUS_REQUEST,
  CHANGE_ADVERT_STATUS_SUCCESS,
  CHANGE_ADVERT_STATUS_FAIL,
  ADVERT_RESULT_REQUEST,
  ADVERT_RESULT_SUCCESS,
  ADVERT_RESULT_FAIL,
  SAVE_ADVERT_FILTER,
  DOWNLOAD_REPORT_DYNAMIC,
  DOWNLOAD_REPORT_DYNAMIC_SUCCESS,
  DOWNLOAD_REPORT_EXCEL_REQUEST,
  DOWNLOAD_REPORT_EXCEL_SUCCESS,
  DOWNLOAD_REPORT_EXCEL_FAIL,
  UPDATE_ADMINISTRATIVE_COST,
  UPDATE_MARKET_COST,
  UPDATE_ADMINISTRATIVE_PERCENTAGE,
  UPDATE_ADMINISTRATIVE_LIMIT,
  UPDATE_ADMINISTRATIVE_AMOUNT

} from '../actions/advertActions';

let initial = {};

function advertReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {

    case DOWNLOAD_REPORT_DYNAMIC:
      return {
        ...state,
        reportResults: {
          isLoading: true
        }
      };

      case DOWNLOAD_REPORT_DYNAMIC_SUCCESS:
        return {
          ...state,
          reportResults: {
            isLoading: false
          }
        };

    case REQUEST_SEARCH_ADVERTS:
      return {
        ...state,
        searchResults: {
          ...state.profiles,
          isSearching: true
        }
      };

    case SEARCH_ADVERTS_SUCCESS:
      return {
        ...state,
        searchResults: {
          isSearching: false,
          data: action.payload
        }
      };
    case SEARCH_ADVERTS_FAIL:
      return {
        ...state,
        searchResults: {
          isSearching: false
        }
      };

    case ACTIVE_ADVERT_REQUEST:
      return {
        ...state,
        activeAdvert: {
          data: {},
          isLoading: true
        }
      };

    case ACTIVE_ADVERT_SUCCESS:
      return {
        ...state,
        activeAdvert: {
          isLoading: false,
          data: action.payload
        }
      };

    case ACTIVE_ADVERT_FAIL:
      return {
        ...state,
        activeAdvert: {
          ...state.activeAdvert,
          isLoading: false
        }
      };

    case ADVERT_HISTORY_REQUEST:
      return {
        ...state,
        advertHistory: {
          ...state.profiles,
          isLoading: true
        }
      };

    case ADVERT_HISTORY_SUCCESS:
      return {
        ...state,
        advertHistory: {
          isLoading: false,
          data: action.payload
        }
      };

    case ADVERT_HISTORY_FAIL:
      return {
        ...state,
        advertHistory: {
          isLoading: false
        }
      };

    case POST_ADVERT_REQUEST:
      return {
        ...state,
        advertsInfo: {
          isPosting: true
        }
      };

    case POST_ADVERT_SUCCESS:
      return {
        ...state,
        advertsInfo: {
          isPosting: false,
          ...action.payload
        }
      };

    case POST_ADVERT_FAIL:
      return {
        ...state,
        advertsInfo: {
          isPosting: false
        }
      };

    case CASES_SEARCH_REQUEST:
      return {
        ...state,
        searchCases: {
          data: [],
          isLoading: true
        }
      };

    case CASES_SEARCH_SUCCESS:
      return {
        ...state,
        searchCases: {
          isLoading: false,
          data: action.payload
        }
      };
    case CASES_SEARCH_FAIL:
      return {
        ...state,
        searchCases: {
          isLoading: false,
          data: []
        }
      };

    case CASES_SEARCH_CANCELLED:
      return {
        ...state,
        searchCases: {
          ...state.searchCases,
          data: []
        }
      };
    case CASES_PRE_SELECT_REQUEST:
      return {
        ...state,
        casesSelections: {
          isLoading: true,
          cases: []
        }
      };
    case CASES_PRE_SELECT_SUCCESS:
      return {
        ...state,
        casesSelections: {
          isLoading: false,
          cases: action.payload.cases
        }
      };
    case CASES_PRE_SELECT_FAIL:
      return {
        ...state,
        casesSelections: {
          isLoading: false,
          cases: []
        }
      };
    case ADVERT_DETAIL_REQUEST:
      return {
        ...state,
        currentAdvertDetail: {
          isLoading: true,
          data: false
        }
      };

    case ADVERT_DETAIL_SUCCESS:
      return {
        ...state,
        currentAdvertDetail: {
          isLoading: false,
          data: action.payload
        }
      };
    case ADVERT_DETAIL_FAIL:
      return {
        ...state,
        currentAdvertDetail: {
          isLoading: false,
          data: false
        }
      };
    case ADVERT_DETAIL_CLEAN:
      return {
        ...state,
        currentAdvertDetail: {
          isLoading: false,
          data: false
        }
      };

    case ADVERT_RESULT_REQUEST:
      return {
        ...state,
        currentAdvertResult: {
          isLoading: true,
          data: false
        }
      };
    case ADVERT_RESULT_SUCCESS:
      return {
        ...state,
        currentAdvertResult: {
          isLoading: false,
          data: action.payload
        }
      };
    case ADVERT_RESULT_FAIL:
      return {
        ...state,
        currentAdvertResult: {
          isLoading: false,
          data: false
        }
      };

    case CLEAN_ADVERT_SEARCH:
      return {
        ...state,
        searchResults: {
          isLoading: false,
          data: []
        }
      };

    case REMOVE_CASE_FROM_ADVERT_REQUEST:
      return {
        ...state,
        removeCaseFROMAdvert: {
          isLoading: true
        }
      };
    case REMOVE_CASE_FROM_ADVERT_SUCCESS:
      return {
        ...state,
        removeCaseFROMAdvert: {
          isLoading: false
        }
      };
    case REMOVE_CASE_FROM_ADVERT_FAIL:
      return {
        ...state,
        removeCaseFROMAdvert: {
          isLoading: false
        }
      };

    case HISTORY_ADVERT_REQUEST:
      return {
        ...state,
        historyAdverts: {
          isLoading: true
        }
      };
    case HISTORY_ADVERT_SUCCESS:
      return {
        ...state,
        historyAdverts: {
          isLoading: false,
          data: action.payload
        }
      };
    case HISTORY_ADVERT_FAIL:
      return {
        ...state,
        historyAdverts: {
          isLoading: false,
          data: false
        }
      };

    case CLEAN_HISTORY_ADVERT:
      return {
        ...state,
        historyAdverts: {
          isLoading: true
        }
      };

    case NOTIFY_BUYERS_REQUEST:
      return {
        ...state,
        actions: {
          isNotifyingBuyers: true
        }
      };

    case NOTIFY_BUYERS_SUCCESS:
      return {
        ...state,
        actions: {
          isNotifyingBuyers: false
        }
      };

    case NOTIFY_BUYERS_FAIL:
      return {
        ...state,
        actions: {
          isNotifyingBuyers: false
        }
      };
    case CLEAN_CURRENT_ADVERT_DETAIL:
      return {
        ...state,
        currentAdvertDetail: {
          data: false,
          isLoading: false
        }
      };
    case CHANGE_ADVERT_STATUS_REQUEST:
      return {
        ...state,
        changeStatusAdvert: {
          isLoading: true
        }
      };
    case CHANGE_ADVERT_STATUS_SUCCESS:
      return {
        ...state,
        changeStatusAdvert: {
          isLoading: false
        }
      };
    case CHANGE_ADVERT_STATUS_FAIL:
      return {
        ...state,
        changeStatusAdvert: {
          isLoading: false
        }
      };
    case SAVE_ADVERT_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case DOWNLOAD_REPORT_EXCEL_REQUEST:
      return {
        ...state,
        reportExcel: {
          isLoading: true
        }
      };
    case DOWNLOAD_REPORT_EXCEL_SUCCESS:
      return {
        ...state,
        reportExcel: {
          isLoading: false
        }
      };
    case DOWNLOAD_REPORT_EXCEL_FAIL:
      return {
        ...state,
        reportExcel: {
          isLoading: false
        }
      };
    case UPDATE_ADMINISTRATIVE_COST:
      const { caseId, nextValue: administrativeCost } = action.payload;
      const cases = state?.currentAdvertDetail?.data?.cases?.map(c => {
        if (c.caseId === caseId) {
          return {
            ...c,
            administrativeCost
          }
        }
        return c;
      });
      return {
        ...state,
        currentAdvertDetail: {
          ...state.currentAdvertDetail,
          data: {
            ...state.currentAdvertDetail.data,
            cases
          }
        },
        searchCases: {
          ...state.searchCases,
          data: cases
        }
      };
    case UPDATE_MARKET_COST:
      const { caseId: caseIdMarketCost, nextValue: marketCost } = action.payload;
      const casesMarketCost = state?.currentAdvertDetail?.data?.cases?.map(c => {
        if (c.caseId === caseIdMarketCost) {
          return {
            ...c,
            marketCost
          }
        }
        return c;
      });
      return {
        ...state,
        currentAdvertDetail: {
          ...state.currentAdvertDetail,
          data: {
            ...state.currentAdvertDetail.data,
            cases: casesMarketCost
          }
        },
        searchCases: {
          ...state.searchCases,
          data: casesMarketCost
        }
      };
    case UPDATE_ADMINISTRATIVE_PERCENTAGE:
      const { caseId: caseIdAdminPercentage, nextValue: percentage } = action.payload;
      const cleanPercentage = percentage && percentage.replaceAll ? percentage?.replaceAll('%', '') : percentage; 
      const casesAdminPercentage = state?.currentAdvertDetail?.data?.cases?.map(c => {
        if (c.caseId === caseIdAdminPercentage) {
          return {
            ...c,
            percentage: +cleanPercentage > 100 ? 100 : cleanPercentage
          }
        }
        return c;
      });
      return {
        ...state,
        currentAdvertDetail: {
          ...state.currentAdvertDetail,
          data: {
            ...state.currentAdvertDetail.data,
            cases: casesAdminPercentage
          }
        },
        searchCases: {
          ...state.searchCases,
          data: casesAdminPercentage
        }
      };
    case UPDATE_ADMINISTRATIVE_LIMIT:
      const { caseId: caseIdAdminLimit, nextValue: limit } = action.payload;
      const cleanLimit = limit && limit.replaceAll ? limit?.replaceAll('$', '') : limit;
      const casesAdminLimit = state?.currentAdvertDetail?.data?.cases?.map(c => {
        if (c.caseId === caseIdAdminLimit) {
          return {
            ...c,
            limit: cleanLimit
          }
        }
        return c;
      });
      return {
        ...state,
        currentAdvertDetail: {
          ...state.currentAdvertDetail,
          data: {
            ...state.currentAdvertDetail.data,
            cases: casesAdminLimit
          }
        },
        searchCases: {
          ...state.searchCases,
          data: casesAdminLimit
        }
      };
    case UPDATE_ADMINISTRATIVE_AMOUNT:
      const { caseId: caseIdAdminAmount, nextValue: amount } = action.payload;
      const casesAdminAmount = state?.currentAdvertDetail?.data?.cases?.map(c => {
        if (c.caseId === caseIdAdminAmount) {
          return {
            ...c,
            amount
          }
        }
        return c;
      });
      return {
        ...state,
        currentAdvertDetail: {
          ...state.currentAdvertDetail,
          data: {
            ...state.currentAdvertDetail.data,
            cases: casesAdminAmount
          }
        },
        searchCases: {
          ...state.searchCases,
          data: casesAdminAmount
        }
      };
    default:
      return state;
  }
}
export default advertReducer;
