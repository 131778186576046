import {
  CASE_FILES_REQUEST,
  CASE_FILES_SUCCESS,
  CASE_FILES_FAIL,
  CASE_FILES_CLEAN,
  DOCUMENTS_CASE_REQUEST,
  DOCUMENTS_CASE_SUCESS,
  DOCUMENTS_CASE_FAIL,
  CASE_FILES_ZIP_REQUEST,
  CASE_FILES_ZIP_SUCCESS,
  CASE_FILES_ZIP_FAIL,
  FILE_RESOURCE_SUCESS,
  GET_FILE_RESOURCELIST_SUCCES,
  GET_FILE_RESOURCELIST,
  GET_FILE_RESOURCE_SUCCESS
} from 'redux/actions/filesActions';
let initial = {};

function filesReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {
    

    case FILE_RESOURCE_SUCESS:
      return {
        ...state,
        fileResults: {
          ...state.fileResults,
          // file:action.payload,
          succes:true,
          isLoading: true
        }
      };

      case GET_FILE_RESOURCE_SUCCESS:
        return {
          ...state,
          fileResults: {
            ...state.fileResults,
            file:action.payload,                        
          }
        };

      case GET_FILE_RESOURCELIST_SUCCES:
        return {
          ...state,
          fileResults: {
            data: action.payload,
            isLoading: true,
            succes:false,
          }
        };
        case GET_FILE_RESOURCELIST:
          return {
            ...state,
            fileResults: {
              data: action.payload,
              isLoading: true,
              succes:false,
            }
          };
    case DOCUMENTS_CASE_REQUEST:
      return {
        ...state,
        caseDocuments: {
          ...state.caseDocuments,
          isLoading: true
        }
      };

    case DOCUMENTS_CASE_SUCESS:
      return {
        ...state,
        caseDocuments: {
          ...state.caseDocuments,
          data: action.payload,
          isLoading: false
        }
      };

    case DOCUMENTS_CASE_FAIL:
      return {
        ...state,
        currentFileList: {
          ...state.caseDocuments,
          data: 'ERROR',
          isLoading: false
        }
      };

    case CASE_FILES_REQUEST:
      return {
        ...state,
        currentFileList: {
          ...state.currentFileList,
          isLoading: true
        }
      };

    case CASE_FILES_SUCCESS:
      return {
        ...state,
        currentFileList: {
          ...state.currentFileList,
          data: action.payload,
          isLoading: false
        }
      };

    case CASE_FILES_FAIL:
      return {
        ...state,
        currentFileList: {
          ...state.currentFileList,
          data: 'ERROR',
          isLoading: false
        }
      };
    case CASE_FILES_CLEAN:
      return {
        ...state,
        currentFileList: {
          isLoading: false
        }
      };
    case CASE_FILES_ZIP_REQUEST:
      return {
        ...state,
        zipDownload: {
          isLoading: true
        }
      };
    case CASE_FILES_ZIP_SUCCESS:
      return {
        ...state,
        zipDownload: {
          isLoading: false
        }
      };
    case CASE_FILES_ZIP_FAIL:
      return {
        ...state,
        zipDownload: {
          isLoading: false
        }
      };
    default:
      return state;
  }
}
export default filesReducer;
