import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SESSION_ACTIVE,
  SET_SESSION,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ACTIVATION_PASSWORD_REQUEST,
  ACTIVATION_PASSWORD_SUCCESS,
  ACTIVATION_PASSWORD_FAIL
} from '../actions/authActions';
let initial = {};
function authReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }
  const isSubmitting = false;
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isSubmitting: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isSubmitting,
        userRejected: action.payload
      };
    case SESSION_ACTIVE:
      return {
        ...state,
        isSubmitting,
        isAuthenticated: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isSubmitting
      };

    case SET_SESSION:
      return {
        isAuthenticated: true,
        isSubmitting
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        email: { ...state.email, isChanging: true }
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        email: { ...state.email, isChanging: false }
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        email: { ...state.email, isChanging: false }
      };

    case ACTIVATION_PASSWORD_REQUEST:
      return {
        ...state
      };

    case ACTIVATION_PASSWORD_SUCCESS:
      return {
        ...state
      };

    case ACTIVATION_PASSWORD_FAIL:
      return {
        ...state
      };

    default:
      return state;
  }
}
export default authReducer;
