import {
  ROLES_GROUP_REQUEST,
  ROLES_SUCCESS_GROUP,
  ROLES_FAIL_GROUP,
  PROFILES_REQUEST,
  PROFILES_FAIL,
  PROFILES_SUCCESS,
  SET_ROLES_DATA,
  REQUEST_COUNTRIES,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL,
  REQUEST_LANGUAGES,
  LANGUAGES_SUCCESS,
  LANGUAGES_FAIL,
  REQUEST_REGIMES,
  REGIMES_SUCCESS,
  REGIMES_FAIL,
  REQUEST_CANCEL_REASONS,
  CANCEL_REASONS_SUCCESS,
  CANCEL_REASONS_FAIL,
  REQUEST_TIMEZONES,
  TIMEZONES_SUCCESS,
  TIMEZONES_FAIL,
  REQUEST_DEPARTMENTS,
  DEPARTMENTS_SUCCESS,
  DEPARTMENTS_FAIL,
  REQUEST_JOBS,
  JOBS_SUCCESS,
  JOBS_FAIL,
  REQUEST_STATUS_USERS,
  STATUS_USERS_SUCCESS,
  STATUS_USERS_FAIL,
  REQUEST_MARKETS,
  REQUEST_MARKETSALL,
  MARKETS_SUCCESS,
  MARKETS_FAIL,
  SUPPLIERS_BY_SERVICE_TYPE_REQUEST,
  SUPPLIERS_BY_SERVICE_TYPE_SUCCESS,
  SUPPLIERS_BY_SERVICE_TYPE_FAIL,
  BRANCHES_BY_PROVIDER_ID_REQUEST,
  BRANCHES_BY_PROVIDER_ID_SUCCESS,
  BRANCHES_BY_PROVIDER_ID_FAIL,
  BRANCHES_BY_PROVIDER_ID_CLEAN,
  VEHICLES_TYPE_REQUEST,
  VEHICLES_TYPE_SUCCESS,
  VEHICLES_TYPE_FAIL,
  ENGINE_TYPE_REQUEST,
  ENGINE_TYPE_SUCCESS,
  ENGINE_TYPE_FAIL,
  STATES_REQUEST,
  STATES_SUCCESS,
  STATES_FAIL,
  OFFER_TYPE_REQUEST,
  OFFER_TYPE_SUCCESS,
  OFFER_TYPE_FAIL,
  GET_VEHICLE_PARTS_REQUEST,
  GET_VEHICLE_PARTS_SUCCESS,
  GET_VEHICLE_PARTS_FAIL,
  CATALOG_DETAIL_REQUEST,
  CATALOG_DETAIL_SUCCESS,
  CATALOG_DETAIL_FAIL,
  PAYMENTS_STATUS_REQUEST,
  PAYMENTS_STATUS_SUCCESS,
  PAYMENTS_STATUS_FAIL,
  ACTION_CATALOG_REQUEST,
  ACTION_CATALOG_SUCCESS,
  ACTION_CATALOG_FAIL,
  ACTION_CATALOG_CLEAN,
  DOCUMENTS_GROUP_REQUEST,
  DOCUMENTS_GROUP_SUCCESS,
  DOCUMENTS_GROUP_FAIL,
  DOCUMENT_TYPES_REQUEST,
  DOCUMENT_TYPES_SUCCESS,
  DOCUMENT_TYPES_FAIL,
  WRECK_TYPES_REQUEST,
  WRECK_TYPES_SUCCESS,
  WRECK_TYPES_FAIL,
  SUB_WRECK_TYPES_REQUEST,
  SUB_WRECK_TYPES_SUCCESS,
  SUB_WRECK_TYPES_FAIL,
  STATUS_TRANSFERS_REQUEST,
  STATUS_TRANSFERS_FAIL,
  STATUS_TRANSFERS_SUCCESS,
  SUPPLIERS_BY_SERVICE_TYPE_GRAL_REQUEST,
  SUPPLIERS_BY_SERVICE_TYPE_GRAL_SUCCESS,
  SUPPLIERS_BY_SERVICE_TYPE_GRAL_FAIL,
  BRANCHES_CRANE_BY_PROVIDER_ID_REQUEST,
  BRANCHES_CRANE_BY_PROVIDER_ID_SUCCESS,
  BRANCHES_CRANE_BY_PROVIDER_ID_FAIL,
  BUYERS_BY_INSURANCE_CARRIER_ID_REQUEST,
  BUYERS_BY_INSURANCE_CARRIER_ID_SUCCESS,
  BUYERS_BY_INSURANCE_CARRIER_ID_FAIL,
  INCIDENCE_TYPE_REQUEST,
  INCIDENCE_TYPE_SUCCESS,
  INCIDENCE_TYPE_FAIL,
  SET_CATALOG_FILTER,
  DAMAGE_PARTS_REQUEST,
  DAMAGE_PARTS_SUCCESS,
  DAMAGE_PARTS_FAIL,
  OTHER_SUB_WRECK_TYPES_REQUEST,
  OTHER_SUB_WRECK_TYPES_SUCCESS,
  OTHER_SUB_WRECK_TYPES_FAIL
} from '../actions/catalogsActions';
import {
  REQUEST_ADVERT_STATUS,
  ADVERT_STATUS_SUCCESS,
  ADVERT_STATUS_FAIL
} from '../actions/searchAdverts';
let initial = {};

function catalogsReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }
  const defaultRolProfiles = {
    ...state,
    rolProfiles: {
      isLoading: false
    }
  };
  let returnState = { ...state };

  let actionCatalogDefault = {
    actionCatalog: {
      isLoading: false
    }
  };

  switch (action.type) {
    case DOCUMENT_TYPES_REQUEST:
      return {
        ...state,
        documentsTypes: {
          ...state.documentsGroup,
          isLoading: true
        }
      };
    case DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        documentsTypes: {
          isLoading: false,
          data: action.payload
        }
      };
    case DOCUMENT_TYPES_FAIL:
      return {
        ...state,
        documentsTypes: {
          isLoading: false
        }
      };

    case DOCUMENTS_GROUP_REQUEST:
      return {
        ...state,
        documentsGroup: {
          ...state.documentsGroup,
          isLoading: true
        }
      };
    case DOCUMENTS_GROUP_SUCCESS:
      return {
        ...state,
        documentsGroup: {
          isLoading: false,
          data: action.payload
        }
      };
    case DOCUMENTS_GROUP_FAIL:
      return {
        ...state,
        documentsGroup: {
          isLoading: false
        }
      };

    case GET_VEHICLE_PARTS_REQUEST:
      return {
        ...state,
        vehicleParts: {
          ...state.vehicleParts,
          isLoading: true
        }
      };
    case GET_VEHICLE_PARTS_SUCCESS:
      return {
        ...state,
        vehicleParts: {
          isLoading: false,
          data: action.payload
        }
      };
    case GET_VEHICLE_PARTS_FAIL:
      return {
        ...state,
        vehicleParts: {
          isLoading: false
        }
      };
    case ROLES_GROUP_REQUEST:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isLoading: true
        }
      };
    case ROLES_SUCCESS_GROUP:
      return {
        ...state,
        profiles: {
          isLoading: false,
          groups: action.profiles
        }
      };
    case ROLES_FAIL_GROUP:
      return {
        ...state,
        profiles: {
          isLoading: false
        }
      };
    case PROFILES_REQUEST:
      return {
        ...state,
        rolProfiles: {
          ...state.rolProfiles,
          isLoading: true
        }
      };
    case PROFILES_SUCCESS:
      return {
        ...state,
        rolProfiles: {
          isLoading: false,
          ...action.profiles
        }
      };
    case PROFILES_FAIL:
      return defaultRolProfiles;

    case REQUEST_COUNTRIES:
      return {
        ...state,
        countries: {
          isLoading: true
        }
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          isLoading: false,
          data: action.countries
        }
      };
    case COUNTRIES_FAIL:
      return {
        ...state,
        countries: {
          isLoading: false
        }
      };

    case REQUEST_LANGUAGES:
      return {
        ...state,
        languages: {
          isLoading: true
        }
      };
    case LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: {
          isLoading: false,
          data: action.languages
        }
      };
    case LANGUAGES_FAIL:
      return {
        ...state,
        languages: {
          isLoading: false
        }
      };

    case REQUEST_REGIMES:
      return {
        ...state,
        regimes: {
          isLoading: true
        }
      };

    case REGIMES_SUCCESS:
      return {
        ...state,
        regimes: {
          isLoading: false,
          data: action.regimes
        }
      };

    case REGIMES_FAIL:
      return {
        ...state,
        regimes: {
          isLoading: false
        }
      };
    
    case REQUEST_CANCEL_REASONS:
      return {
        ...state,
        cancelReasons: {
          isLoading: true
        }
      };

    case CANCEL_REASONS_SUCCESS:
      return {
        ...state,
        cancelReasons: {
          isLoading: false,
          data: action.cancelReasons
      }
    };

    case CANCEL_REASONS_FAIL:
      return {
        ...state,
        cancelReasons: {
          isLoading: false,
          data : []
        }
      };

    case REQUEST_TIMEZONES:
      return {
        ...state,
        timeZones: {
          isLoading: true
        }
      };
    case TIMEZONES_SUCCESS:
      return {
        ...state,
        timeZones: {
          isLoading: false,
          data: action.timeZones
        }
      };
    case TIMEZONES_FAIL:
      return {
        ...state,
        timeZones: {
          isLoading: false
        }
      };

    case REQUEST_ADVERT_STATUS:
      return {
        ...state,
        advertStatus: {
          isLoading: true
        }
      };

    case ADVERT_STATUS_SUCCESS:
      return {
        ...state,
        advertStatus: {
          isLoading: false,
          data: action.advertStatus
        }
      };
    case ADVERT_STATUS_FAIL:
      return {
        ...state,
        advertStatus: {
          isLoading: false
        }
      };

    case REQUEST_DEPARTMENTS:
      return {
        ...state,
        departments: {
          isLoading: true
        }
      };
    case DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: {
          isLoading: false,
          data: action.departments
        }
      };
    case DEPARTMENTS_FAIL:
      return {
        ...state,
        departments: {
          isLoading: false
        }
      };

    case REQUEST_JOBS:
      return {
        ...state,
        jobs: {
          isLoading: true
        }
      };

    case JOBS_SUCCESS:
      return {
        ...state,
        jobs: {
          isLoading: false,
          data: action.jobs
        }
      };

    case JOBS_FAIL:
      return {
        ...state,
        jobs: {
          isLoading: false
        }
      };

    case VEHICLES_TYPE_REQUEST:
      return {
        ...state,
        vehicleTypes: {
          isLoading: true
        }
      };

    case VEHICLES_TYPE_SUCCESS:
      return {
        ...state,
        vehicleTypes: {
          isLoading: false,
          data: action.payload
        }
      };

    case VEHICLES_TYPE_FAIL:
      return {
        ...state,
        vehicleTypes: {
          isLoading: false
        }
      };

    case ENGINE_TYPE_REQUEST:
      return {
        ...state,
        engineTypes: {
          isLoading: true
        }
      };

    case ENGINE_TYPE_SUCCESS:
      return {
        ...state,
        engineTypes: {
          isLoading: false,
          data: action.payload
        }
      };

    case ENGINE_TYPE_FAIL:
      return {
        ...state,
        engineTypes: {
          isLoading: false
        }
      };

    case STATES_REQUEST:
      return {
        ...state,
        states: {
          isLoading: true
        }
      };

    case STATES_SUCCESS:
      return {
        ...state,
        states: {
          isLoading: false,
          data: action.payload
        }
      };

    case STATES_FAIL:
      return {
        ...state,
        states: {
          isLoading: false
        }
      };

    case OFFER_TYPE_REQUEST:
      return {
        ...state,
        offerTypes: {
          isLoading: true
        }
      };

    case OFFER_TYPE_SUCCESS:
      return {
        ...state,
        offerTypes: {
          isLoading: false,
          data: action.payload
        }
      };

    case OFFER_TYPE_FAIL:
      return {
        ...state,
        offerTypes: {
          isLoading: false
        }
      };

    case REQUEST_STATUS_USERS:
      return {
        ...state
      };
    case STATUS_USERS_SUCCESS:
      return {
        ...state,
        usersStatus: action.status
      };
    case STATUS_USERS_FAIL:
      return returnState;
    case REQUEST_MARKETS:
      return returnState;
    case REQUEST_MARKETSALL:
      return returnState;
    case MARKETS_SUCCESS:
      return {
        ...state,
        markets: action.markets
      };
    case MARKETS_FAIL:
      return returnState;

    case SET_ROLES_DATA:
      return {
        ...state,
        profiles: action.profiles
      };
    case SUPPLIERS_BY_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        supplierByServiceType: {
          ...state.supplierByServiceType,
          isLoading: true,
          data: false
        }
      };

    case SET_CATALOG_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    // case SUPPLIERS_BY_SERVICE_TYPE_SUCCESS:
    //   return {
    //     ...state,
    //     supplierByServiceType: {
    //       ...state.supplierByServiceType,
    //       isLoading: false,
    //       data: {
    //         ...state.supplierByServiceType?.data,
    //         [action.payload.serviceTypeId]: action.payload.suppliers
    //       }
    //     }
    //   };
    case SUPPLIERS_BY_SERVICE_TYPE_GRAL_REQUEST:
      return {
        ...state,
        supplierByServiceTypeGral: {
          ...state.supplierByServiceTypeGral,

          [action.payload.serviceTypeId]: {
            ...state.supplierByServiceType?.data[action.payload.serviceTypeId],
            isLoading: true
          }
        }
      };
    case SUPPLIERS_BY_SERVICE_TYPE_GRAL_SUCCESS:
      return {
        ...state,
        supplierByServiceTypeGral: {
          ...state.supplierByServiceTypeGral,

          [action.payload.serviceTypeId]: { isLoading: false, data: action.payload.suppliers }
        }
      };
    case SUPPLIERS_BY_SERVICE_TYPE_GRAL_FAIL:
      return {
        ...state,
        supplierByServiceTypeGral: {
          ...state.supplierByServiceTypeGral
        }
      };
    case SUPPLIERS_BY_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        supplierByServiceType: {
          ...state.supplierByServiceType,
          isLoading: false,
          data: action.payload.suppliers
        }
      };
    case SUPPLIERS_BY_SERVICE_TYPE_FAIL:
      return {
        ...state,
        supplierByServiceType: {
          ...state.supplierByServiceType,
          isLoading: false,
          data: false
        }
      };

    case BRANCHES_BY_PROVIDER_ID_REQUEST:
      return {
        ...state,
        branchesByProviderId: {
          isLoading: true,
          data: false
        }
      };
    case BRANCHES_BY_PROVIDER_ID_SUCCESS:
      return {
        ...state,
        branchesByProviderId: {
          ...state.branchesByProviderId,
          isLoading: false,
          data: action.payload.branches
        }
      };
    case BRANCHES_BY_PROVIDER_ID_FAIL:
      return {
        ...state,
        branchesByProviderId: {
          ...state.branchesByProviderId,
          isLoading: false,
          data: false
        }
      };
    case BRANCHES_BY_PROVIDER_ID_CLEAN:
      return {
        ...state,
        branchesByProviderId: {
          isLoading: false,
          data: false
        },
        supplierByServiceType: {
          isLoading: false,
          data: false
        }
      };
    case BUYERS_BY_INSURANCE_CARRIER_ID_REQUEST:
      return {
        ...state,
        buyersByInsuranceId: {
          isLoading: true,
          data: false
        }
      };
    case BUYERS_BY_INSURANCE_CARRIER_ID_SUCCESS:
      return {
        ...state,
        buyersByInsuranceId: {
          ...state.buyersByInsuranceId,
          isLoading: false,
          data: action?.payload
        }
      };

    case BUYERS_BY_INSURANCE_CARRIER_ID_FAIL:
      return {
        ...state,
        buyersByInsuranceId: {
          ...state.buyersByInsuranceId,
          isLoading: false,
          data: false
        }
      };

    case INCIDENCE_TYPE_REQUEST:
      return {
        ...state,
        incidenceType: {
          ...state?.incidenceType,
          isLoading: true
        }
      };

    case INCIDENCE_TYPE_SUCCESS:
      return {
        ...state,
        incidenceType: {
          ...state?.incidenceType,
          isLoading: false,
          data: action?.payload
        }
      };

    case INCIDENCE_TYPE_FAIL:
      return {
        ...state,
        incidenceType: {
          ...state?.incidenceType,
          isLoading: false
        }
      };

    case BRANCHES_CRANE_BY_PROVIDER_ID_REQUEST:
      return {
        ...state,
        branchesCraneByProviderId: {
          isLoading: true,
          data: false
        }
      };
    case BRANCHES_CRANE_BY_PROVIDER_ID_SUCCESS:
      return {
        ...state,
        branchesCraneByProviderId: {
          ...state.branchesCraneByProviderId,
          isLoading: false,
          data: action.payload
        }
      };
    case BRANCHES_CRANE_BY_PROVIDER_ID_FAIL:
      return {
        ...state,
        branchesCraneByProviderId: {
          ...state.branchesCraneByProviderId,
          isLoading: false,
          data: false
        }
      };

    case CATALOG_DETAIL_REQUEST:
      return {
        ...state,
        catalogDetail: {
          isLoading: true,
          data: false
        }
      };
    case CATALOG_DETAIL_SUCCESS:
      return {
        ...state,
        catalogDetail: {
          isLoading: false,
          data: action.payload
        }
      };
    case CATALOG_DETAIL_FAIL:
      return {
        ...state,
        catalogDetail: {
          isLoading: false,
          data: false
        }
      };
    case PAYMENTS_STATUS_REQUEST:
      return {
        ...state,
        paymentsStatus: {
          isLoading: true,
          data: []
        }
      };
    case PAYMENTS_STATUS_SUCCESS:
      return {
        ...state,
        paymentsStatus: {
          isLoading: false,
          data: action.payload
        }
      };
    case PAYMENTS_STATUS_FAIL:
      return {
        ...state,
        paymentsStatus: {
          isLoading: false,
          data: []
        }
      };

    case ACTION_CATALOG_REQUEST:
      return {
        ...state,
        actionCatalog: {
          isLoading: true
        }
      };
    case ACTION_CATALOG_SUCCESS:
    case ACTION_CATALOG_CLEAN:
      return {
        ...state,
        ...actionCatalogDefault
      };

    case ACTION_CATALOG_FAIL:
      return {
        ...state,
        actionCatalog: {
          isLoading: false
        }
      };

    case WRECK_TYPES_REQUEST:
      return {
        ...state,
        wreckTypes: {
          ...state.wreckTypes,
          isLoading: true
        }
      };

    case WRECK_TYPES_SUCCESS:
      return {
        ...state,
        wreckTypes: {
          ...state.wreckTypes,
          data: action.payload,
          isLoading: false
        }
      };

    case WRECK_TYPES_FAIL:
      return {
        ...state,
        wreckTypes: {
          data: [],
          isLoading: false
        }
      };

    case SUB_WRECK_TYPES_REQUEST:
      return {
        ...state,
        subWreckTypes: {
          ...state.subWreckTypes,
          isLoading: true
        }
      };

    case SUB_WRECK_TYPES_SUCCESS:
      return {
        ...state,
        subWreckTypes: {
          data: action.payload,
          isLoading: false
        }
      };

    case SUB_WRECK_TYPES_FAIL:
      return {
        ...state,
        subWreckTypes: {
          data: [],
          isLoading: false
        }
      };

    case STATUS_TRANSFERS_REQUEST:
      return {
        ...state,
        statusTransfers: {
          ...state.statusTransfers,
          isLoading: true
        }
      };

    case STATUS_TRANSFERS_SUCCESS:
      return {
        ...state,
        statusTransfers: {
          data: action.payload,
          isLoading: false
        }
      };

    case STATUS_TRANSFERS_FAIL:
      return {
        ...state,
        statusTransfers: {
          data: [],
          isLoading: false
        }
      };

    case DAMAGE_PARTS_REQUEST: 
      return {
        ...state,
        damageParts: {
          data: [],
          isLoading: true
        }
      };

    case DAMAGE_PARTS_SUCCESS:
      return {
        ...state,
        damageParts: {
          data: action.payload,
          isLoading: false
        }
      };
    
    case DAMAGE_PARTS_FAIL:
      return {
        ...state,
        damageParts: {
          data: [],
          isLoading: false
        }
      };
    case OTHER_SUB_WRECK_TYPES_REQUEST:
      return {
        ...state,
        otherSubWreckTypes: {
          isLoading: true
        }
      };
    case OTHER_SUB_WRECK_TYPES_SUCCESS:
      return {
        ...state,
        otherSubWreckTypes: {
          isLoading: false,
          data: action.payload
        }
      };
    case OTHER_SUB_WRECK_TYPES_FAIL:
      return {
        ...state,
        otherSubWreckTypes: {
          isLoading: false
        }
      };

    default:
      return state;
  }
}
export default catalogsReducer;
