import {
  MENU_REQUEST,
  MENU_SET,
  MENU_FAIL,
  USER_SET_DATA,
  TOGGLE_SIDEBAR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  PROFILE_INFO_REQUEST,
  PROFILE_INFO_SUCCESS,
  PROFILE_INFO_FAIL
} from '../actions/userActions';
let initial = {};

function userReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  let returnState = { ...state };

  switch (action.type) {
    case MENU_REQUEST:
      return returnState;
    case MENU_SET:
      return {
        ...state,
        menu: { ...action.menu }
      };
    case MENU_FAIL:
      return returnState;
    case USER_SET_DATA:
      return {
        ...state,
        info: { ...action.user }
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sideBar: { ...state.sideBar, isCollapsed: action.isCollapsed }
      };

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        password: { ...state.password, isChanging: true }
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        password: { ...state.password, isChanging: false }
      };

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        password: { ...state.password, isChanging: false }
      };

    case PROFILE_INFO_REQUEST:
      return {
        ...state,
        avatar: { isLoading: true }
      };

    case PROFILE_INFO_SUCCESS:
      return {
        ...state,
        avatar: { data: action.payload, isLoading: false }
      };
    case PROFILE_INFO_FAIL:
      return {
        ...state,
        avatar: { isLoading: false }
      };

    default:
      return state;
  }
}
export default userReducer;
