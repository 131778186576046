import { COMPONENTS_FAIL, COMPONENTS_FLOAT_MENU_SUCCESS, COMPONENTS_HEADER_MENU_SUCCESS, COMPONENTS_REQUEST } from "redux/actions/componentsActions";
let initial = {};
const componentReducer = (state = {}, action) => {
    if (typeof state === 'undefined') {
        state = initial;
    }

    switch (action.type) {
        case COMPONENTS_REQUEST:
            return {
                ...state,
                isLoading : true
            };
        
        case COMPONENTS_FLOAT_MENU_SUCCESS:
            return {
                ...state,
                isLoading : false,
                type :{
                    ...state.type,
                    floatingMenu : action.payload
                }
            };
        
        case COMPONENTS_HEADER_MENU_SUCCESS:
            return {
                ...state,
                isLoading : false,
                type :{
                    ...state.type,
                    headerMenu : action.payload
                }
            };
        
        case COMPONENTS_FAIL:
            return {
                ...state,
                isLoading : false,
                type :{
                    floatingMenu : []
                }
            };
        
        default:
            return state;
    }
};

export default componentReducer;