import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  SEARCH_CLEAN,
  SAVE_SEARCH
} from '../actions/searchActions';

let initial = {};
function searchReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }
  let defaultState = {
    isSearching: false
  };
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        isSearching: true
      };
    case SAVE_SEARCH : 
      return { ...state, params: action.payload }
    case SEARCH_SUCCESS:
      return { ...state, ...defaultState, params: action.payload };
    case SEARCH_FAIL:
      return { ...state, ...defaultState };
    case SEARCH_CLEAN:
      return { ...state, ...defaultState, params: null };
    default:
      return state;
  }
}
export default searchReducer;
