import { UPDATE_ATTACHMENTS, CLEAN_ATTACHMENTS } from '../actions/attachmentsActions';
let initial = {};
function attachmentsReducer(state, action) {
  if (typeof state === 'undefined') {
    state = initial;
  }

  switch (action.type) {
    case UPDATE_ATTACHMENTS:
      return { ...state, documents: action.payload };
    case CLEAN_ATTACHMENTS:
      return {
        documents: []
      };

    default:
      return state;
  }
}
export default attachmentsReducer;
